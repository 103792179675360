import React, { useCallback, useMemo, useState } from "react";

import { styled, AutocompleteMenu } from "@puzzle/ui";
import { CaretDown } from "@puzzle/icons";

import { useActiveCompany } from "components/companies";
import Analytics from "lib/analytics";
import { useSize } from "react-use";
import { InactiveCompanyFragment } from "components/companies/graphql.generated";
import { useRouter } from "next/router";
import { isDynamicRoute, getClosestStaticRoute } from "lib/routes";

const Button = styled("button", {
  border: "1px solid transparent",

  appearance: "none",
  background: "none",
  width: "100%",
  padding: "$1h",
  fontWeight: "$bold",
  fontSize: "$body",
  lineHeight: "16px",
  letterSpacing: "$bodyXS",
  color: "$gray300",
  cursor: "pointer",
  userSelect: "none",
  outline: "none",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "$1",

  "&:hover, &:focus": {
    color: "$gray200",
  },

  "& > *:first-child": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minWidth: "0",
  },

  "&:disabled": {
    pointerEvents: "none",
  },

  "&:not(:disabled)": {
    borderColor: "$gray600",
    borderRadius: "40px",
  },
});

export const CompanyMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { companies, company, setActiveCompanyId } = useActiveCompany<true>();
  const hasManyCompanies = companies.length > 1;
  const router = useRouter();

  const setCompany = useCallback(
    (company: InactiveCompanyFragment) => {
      Analytics.companyChanged({
        companyId: company.id,
      });

      setActiveCompanyId(company.id);
      setIsOpen(false);

      if (isDynamicRoute(router.pathname)) {
        router.replace(getClosestStaticRoute(router.pathname));
      }
    },
    [setActiveCompanyId, router]
  );

  const sortedCompanies = useMemo(() => {
    return [...companies].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [companies]);

  const [trigger, { width }] = useSize(
    <Button disabled={!hasManyCompanies}>
      <span>{company.name}</span>
      {hasManyCompanies && <CaretDown />}
    </Button>
  );

  if (!hasManyCompanies) {
    return trigger;
  }

  const widthOfClearButton = 42;
  return (
    <AutocompleteMenu<InactiveCompanyFragment, false, true, false>
      open={isOpen}
      options={sortedCompanies}
      getOptionKey={(o) => o.id}
      getOptionLabel={(o) => o.name || ""}
      value={company}
      isOptionEqualToValue={(a, b) => {
        return a.id === b.id;
      }}
      onChange={(_, value) => {
        if (value) setCompany(value);
      }}
      trigger={trigger}
      css={{ width: width - widthOfClearButton }}
    />
  );
};

export default CompanyMenu;
