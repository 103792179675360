import React from "react";
import { Button, IconButton, styled, ScrollArea } from "@puzzle/ui";
import { useWizardContext } from "components/common/Wizard";
import { Body, Container, Header, HeaderTitle } from "./shared";
import { Close } from "@puzzle/icons";

const Title = styled("h1", {
  marginTop: "$4",
  marginBottom: "$3",
  textVariant: "$heading3",
  textAlign: "center",
  color: "$gray200",
});

const Section = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: 400,
});

const Content = styled("p", {
  marginBottom: "$3",
  textVariant: "$bodyS",
  textAlign: "center",
  color: "$gray400",
});

export const AIHome = ({ onOpenChange }: { onOpenChange: () => void }) => {
  const { goToNextStep } = useWizardContext();

  return (
    <>
      <Header>
        <HeaderTitle>
          <div>Ask AI about my business</div>
        </HeaderTitle>
        <div>
          <IconButton onClick={onOpenChange} autoFocus>
            <Close color="currentColor" size={12} />
          </IconButton>
        </div>
      </Header>

      <ScrollArea css={{ flex: 1 }}>
        <Body>
          <Container>
            <Section>
              <Title>Your AI accounting assistant</Title>
              <Content>
                You can ask from a predefined list of questions and receive information that
                pertains specifically to your business.
              </Content>
              <Button fullWidth onClick={goToNextStep}>
                Ask a question
              </Button>
            </Section>
          </Container>
        </Body>

        <ScrollArea.Scrollbar orientation="vertical" css={{ width: "10px !important" }}>
          <ScrollArea.Thumb variant="shadowed" />
        </ScrollArea.Scrollbar>
      </ScrollArea>
    </>
  );
};
