import React from "react";

import { Button, Dialog, Stack, Text } from "@puzzle/ui";

import UpgradeButton from "components/featureGate/UpgradeButton";

import { MonthlyExceededAlert } from "components/settings/Billing/MonthlyExceededAlert";
import { Box, S } from "ve";
import { useTrialExpiredModal } from "../useTrialExpiredModal";

export const TrialExpiredModal = () => {
  const { isModalVisible, handleOpenChange, exceeding } = useTrialExpiredModal();
  return (
    <Dialog size="large" open={isModalVisible} onOpenChange={handleOpenChange}>
      <Dialog.Title css={{ textVariant: "$headingM" }}>Your trial has expired.</Dialog.Title>

      <Dialog.Body css={{ padding: "$3" }}>
        <Stack direction="vertical" gap="2">
          <Stack gap="2">
            <Text variant="body" css={{ color: "$gray400" }}>
              Now that your free trial of our <Text css={{ color: "$gray200" }}>Advanced</Text> plan
              has ended, we've downgraded you to the{" "}
              <Text css={{ color: "$gray200" }}>Formation</Text> (Free) plan.
            </Text>
            {exceeding && <MonthlyExceededAlert />}
            <Text css={{ textVariant: "$bodyS", color: "$gray400" }}>
              Don't worry, this change won't delete or undo any of the work you've done to date.
            </Text>
          </Stack>
          <Box
            css={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: S["2"] }}
          >
            {!exceeding && (
              <Button variant={"secondary"} onClick={() => handleOpenChange(false)}>
                Stay on free plan
              </Button>
            )}
            <UpgradeButton
              title={"Upgrade"}
              showIcon={false}
              onClick={() => handleOpenChange(false)}
            />
          </Box>
        </Stack>
      </Dialog.Body>
    </Dialog>
  );
};
