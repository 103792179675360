import React from "react";
import { Dialog, Field, Select, useToasts } from "@puzzle/ui";
import { roles } from "components/companies/common";
import { UserPosition } from "graphql/types";
import { Controller, useForm } from "react-hook-form";
import { Button, Text } from "ve";
import { bodyContainer } from "./MoreAboutYouModal.css";
import { useUpdateCompanyMembershipAsUserMutation } from "components/dashboard/Users/graphql.generated";
import { useActiveCompany } from "components/companies";
import Analytics from "lib/analytics";
import { SelfMembershipFragment } from "components/users/graphql.generated";
import { useShowIntroTour } from "components/dashboard/Dashboard/IntroTour/useShowIntroTour";
import { useMoreAboutYouModalStore } from "./moreAboutYouModalStore";

function MoreAboutYou({ membership, refetch }: { membership: SelfMembershipFragment, refetch: () => void }) {
  const { toast } = useToasts();
  const [updateCompanyMembershipAsUser, result] = useUpdateCompanyMembershipAsUserMutation();
  const {
    control,
    handleSubmit,
    formState,
  } = useForm<{
    position: UserPosition
  }>({
  });

  const onSubmit = async ({ position }: { position: UserPosition }) => {
    Analytics.userPositionChanged({ oldValue: membership.position ?? "", newValue: position });
    await updateCompanyMembershipAsUser({
      variables: { input: { id: membership.id, position } },
    });
    await refetch();
    toast({
      message:
        "Your info is saved",
      status: "success",
    });
  };
  return (
    <Dialog width={480} open={!membership.position}>
      <Dialog.Title showClose={false} css={{ textVariant: "$headingM", py: '$2' }}>What's your position?</Dialog.Title>
      <Dialog.Body className={bodyContainer}>
        <Text variant="bodyS">Set your position so we can personalize your experience.</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="position"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <Field label="Your position">
                  <Select
                    value={field.value}
                    options={[
                      { value: UserPosition.Founder, label: roles[UserPosition.Founder] },
                      { value: UserPosition.CompanyEmployee, label: roles[UserPosition.CompanyEmployee] },
                      { value: UserPosition.OutsourcedAccountantOrCfo, label: roles[UserPosition.OutsourcedAccountantOrCfo] }
                    ]}
                    placeholder="Select an option"
                    onSelectionChange={(val) => {
                      field.onChange({
                        target: {
                          value: val,
                          name: field.name,
                        },
                      });
                    }}
                  ></Select>
                </Field>
              );
            }}
          />  
       </form>
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Button
          data-testid="submit_new_segment"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
          loading={result?.loading || formState.isSubmitting}>
          Submit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

export function MoreAboutYouModal() {
  const { showTourInvitee } = useShowIntroTour();
  const { isMoreAboutYouModalShown } = useMoreAboutYouModalStore();
  // we just want user to see the MoreAboutYouModal only in the next login after user
  // seen the PostOnboardingModal or when the PostOnboardingModal is not enabled.
  const shouldShowModal = !showTourInvitee && isMoreAboutYouModalShown;
  const { membership, refetchActiveCompany } = useActiveCompany();
  return membership && !membership.position && shouldShowModal ? <MoreAboutYou membership={membership} refetch={refetchActiveCompany}/> : null; 
}
