import React from "react";
import { styled } from "@puzzle/ui";
import { BookOpen, ChartRising, LinkExternal, ChevronLeft } from "@puzzle/icons";
import { useActiveCompany } from "components/companies";
import useAppRouter from "lib/useAppRouter";
import { Route } from "lib/routes";
import Analytics from "lib/analytics";
import { BookkeeperPlan } from "graphql/types";

import financial_overview from "public/images/postOnboarding/financial_overview.png";
import understanding_spending from "public/images/postOnboarding/understanding_spending.png";
import view_accounting_checklist from "public/images/postOnboarding/view_accounting_checklist.png";
import categorize_transactions from "public/images/postOnboarding/categorize_transactions.png";
import spotlight from "public/images/postOnboarding/spotlight.png";

import Image from "next/image";

import { AnalyticsTriggeredFrom, AnalyticsFeatures, AnalyticsSections } from "./analytics";

import { color, S, Box, Button, Stack, Text, UnstyledButton, vars } from "ve";

type PanelProps = {
  title: string;
  subTitle: string;
  onClick: () => void;
  wide?: boolean;
};

const Panel = ({
  title,
  subTitle,
  onClick,
  wide,
  children,
}: React.PropsWithChildren<PanelProps>) => {
  return (
    <UnstyledButton
      css={{ width: wide ? "360px" : "300px", borderRadius: "8px" }}
      onClick={onClick}
    >
      <Box
        css={{
          padding: S["2"],
          paddingBottom: 0,
          backgroundColor: "#191923",
          borderRadius: `${vars.radii[2]} ${vars.radii[2]} 0 0`,
        }}
      >
        {children}
      </Box>

      <Box
        css={{
          display: "flex",
          flexDirection: "column",
          padding: S["2"],
          backgroundColor: color.gray700,
          borderRadius: `0 0 ${vars.radii[2]}${vars.radii[2]}`,
          alignItems: "flex-start",
          gap: S["0h"],
        }}
      >
        <Text variant="bodyS" color="gray200">
          {title}
        </Text>

        <Box
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Text variant="bodyXS" color="gray400">
            {subTitle}
          </Text>
          <LinkExternal width={12} height={12} />
        </Box>
      </Box>
    </UnstyledButton>
  );
};

const GroupContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  backgroundColor: "$mauve800",
  paddingTop: "10px",
  paddingLeft: "$2",
  paddingRight: "$2",
  paddingBottom: "$2",
});

const CircledIconLarge = styled("div", {
  minWidth: "36px",
  width: "36px",
  height: "36px",
  backgroundColor: "rgba(33, 31, 51, 0.4)",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CircledIconSmall = styled("div", {
  minWidth: "24px",
  width: "24px",
  height: "24px",
  backgroundColor: "$mauve800",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export type ExplorePuzzlePaneProps = {
  onClose: () => void;
  onGoBack?: () => void; // If this is omitted, render standalone variant
  triggeredFrom: AnalyticsTriggeredFrom;
};

const PanelRowHeading = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "$1",
  gap: "$2",
});

const PanelRowContainer = styled("div", {
  padding: "$2",
  borderRadius: "$1",
  border: "1px solid $white100",
});

const PanelRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
});

export const ExplorePuzzlePane = ({ onClose, onGoBack, triggeredFrom }: ExplorePuzzlePaneProps) => {
  const { goToPath } = useAppRouter();
  const { company } = useActiveCompany<true>();
  const diy = company.bookkeeperPlan === BookkeeperPlan.DoItMyself;
  const wide = !onGoBack;

  const handlePanelClick = (
    route: Route,
    section: AnalyticsSections,
    feature: AnalyticsFeatures,
    url?: string
  ) => {
    goToPath(route);
    if (url) {
      window.open(url, "_blank");
    }
    onClose();
    Analytics.exploreFeaturesOptionSelected({
      triggeredFrom,
      section,
      feature,
    });
  };

  const skipAndClose = () => {
    onClose();
    Analytics.exploreFeaturesOptionSelected({
      triggeredFrom,
      section: AnalyticsSections.Skip,
      feature: "",
    });
  };

  return (
    <Box css={{ padding: S["$2h"] }}>
      {onGoBack && (
        <Text variant="heading3" color="gray50">
          Explore Puzzle's features, select where to start:
        </Text>
      )}

      <Stack gap={S["$1h"]} css={{ marginTop: S["$2h"] }}>
        <PanelRowContainer>
          <PanelRowHeading>
            <CircledIconLarge>
              <ChartRising color="#50FAAB" width={12} height={12} />
            </CircledIconLarge>
            <Text variant="bodyM" color="gray200">
              Understand company finances
            </Text>
          </PanelRowHeading>

          <PanelRow>
            <Panel
              title="See my financial overview"
              subTitle="Go to Dashboard"
              onClick={() =>
                handlePanelClick(
                  Route.home,
                  AnalyticsSections.UnderstandFinances,
                  AnalyticsFeatures.SeeMyFinancialOverview
                )
              }
              wide={wide}
            >
              <Image
                src={financial_overview}
                alt="financial overview"
                width={wide ? 307 : 277}
                height={149}
              />
            </Panel>
            <Panel
              title="Understand my spending"
              subTitle="Go to Spend Explorer"
              onClick={() =>
                handlePanelClick(
                  Route.spending,
                  AnalyticsSections.UnderstandFinances,
                  AnalyticsFeatures.UnderstandMySpending
                )
              }
              wide={wide}
            >
              <Image
                src={understanding_spending}
                alt="financial overview"
                width={wide ? 307 : 277}
                height={149}
              />
            </Panel>
          </PanelRow>
        </PanelRowContainer>

        <PanelRowContainer>
          <PanelRowHeading>
            <CircledIconLarge>
              <BookOpen color="#50FAAB" width={12} height={12} />
            </CircledIconLarge>
            <Text variant="bodyM" color="gray200">
              Get my financials in order
            </Text>
          </PanelRowHeading>

          <PanelRow>
            {diy ? (
              <Panel
                title="View an accounting checklist"
                subTitle="Go to Monthly Checklist"
                onClick={() =>
                  handlePanelClick(
                    Route.checklist,
                    AnalyticsSections.GetFinancesInOrder,
                    AnalyticsFeatures.ViewAccountingChecklist
                  )
                }
                wide={wide}
              >
                <Image
                  src={view_accounting_checklist}
                  alt="view acccounting checklist"
                  width={wide ? 307 : 277}
                  height={149}
                />
              </Panel>
            ) : (
              <Panel
                title="Identify significant financial changes"
                subTitle="Go to Spotlight"
                onClick={() =>
                  handlePanelClick(
                    Route.home,
                    // activate spotlight..??
                    AnalyticsSections.GetFinancesInOrder,
                    AnalyticsFeatures.ViewAccountingChecklist
                  )
                }
                wide={wide}
              >
                <Image src={spotlight} alt="spotlight" width={wide ? 307 : 277} height={149} />
              </Panel>
            )}

            <Panel
              title="Categorize my transactions"
              subTitle="Go to Transactions"
              onClick={() =>
                handlePanelClick(
                  Route.transactions,
                  AnalyticsSections.GetFinancesInOrder,
                  AnalyticsFeatures.CategorizeTransactions
                )
              }
              wide={!onGoBack}
            >
              <Image
                src={categorize_transactions}
                alt="categorize transactions"
                width={307}
                height={149}
              />
            </Panel>
          </PanelRow>
        </PanelRowContainer>
      </Stack>

      {onGoBack && (
        <Box
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button variant="minimal" onClick={onGoBack} css={{ paddingLeft: "0" }}>
            <ChevronLeft css={{ marginBottom: "-2px" }} /> Back
          </Button>
          <Button onClick={skipAndClose} variant="secondary" css={{}}>
            Skip
          </Button>
        </Box>
      )}
    </Box>
  );
};
