import React, { ReactNode, useState } from "react";

interface AIContext {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
}

const AIContext = React.createContext<AIContext | null>(null);

export const AIProvider = ({ children }: { children?: ReactNode }) => {
  const [prompt, setPrompt] = useState("");

  return <AIContext.Provider value={{ prompt, setPrompt }}>{children}</AIContext.Provider>;
};

export function useAIContext() {
  const context = React.useContext(AIContext);
  if (context === null) {
    throw new Error("AIContext not found");
  }
  return context;
}
