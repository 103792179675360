import React from "react";

import { Box, color, Text } from "ve";

export const YearlyDiscountBadge = ({ css }: { css?: React.CSSProperties }) => {
  return (
    <Box
      css={{
        ...css,
        backgroundColor: color.greenBackground,
        borderRadius: "29px",
        paddingLeft: "8px",
        paddingRight: "9px",
        paddingTop: "3px",
        paddingBottom: "2px",
      }}
    >
      <Text variant="bodyXS" color="green600">
        -15%
      </Text>
    </Box>
  );
};

export const BasicBadge = ({ text, css }: { text: string; css?: React.CSSProperties }) => {
  return (
    <Box
      css={{
        ...css,
        backgroundColor: "#121718",
        borderRadius: "36px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "4px",
        paddingBottom: "6px",
        boxShadow: "0px -1px 0px 0px rgba(255, 255, 255, 0.10) inset",
      }}
    >
      <Text variant="headingM" color="gray400">
        {text}
      </Text>
    </Box>
  );
};
