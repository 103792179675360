import { ColorfulCrown } from "@puzzle/icons";
import useMonetization from "components/monetization/useMonetization";
import { differenceInCalendarDays } from "date-fns";
import React, { useMemo } from "react";
import { Stack, vars, Button, Text } from "ve";
import { useActiveCompany } from "components/companies";
import { now, parseDate } from "@internationalized/date";
import { freeTrialExpiringStyle } from "./trailInfoPanel.css";

export function TrialInfoPanel() {
  const { company } = useActiveCompany<true>();
  const { showUpgradeModal } = useMonetization();
  const daysLeftingOnFreeTrialContent = useMemo(() => {
    const freeTrialEndDate = company.puzzlePlanSubscriptions.find(
      (subscription) => subscription.plan.name === "Premium Free Trial"
    )?.endDate;
    if (!freeTrialEndDate) return null;
    const daysLeft = differenceInCalendarDays(
      parseDate(freeTrialEndDate).toDate("UTC"),
      now("UTC").toDate()
    );

    const totalDaysLeft = Math.min(Math.abs(daysLeft), 14);
    const sufix = totalDaysLeft !== 1 ? "s" : "";
    return `${totalDaysLeft} day${sufix} left${sufix} on free trial`;
  }, [company.puzzlePlanSubscriptions]);

  return (
    <div className={freeTrialExpiringStyle}>
      <Stack direction="horizontal" css={{ alignItems: "center" }} gap={vars.space["1"]}>
        <ColorfulCrown />
        <Text>{daysLeftingOnFreeTrialContent}</Text>
      </Stack>
      <Button fullWidth variant="gold" onClick={() => showUpgradeModal()}>
        View plans
      </Button>
    </div>
  );
}
