/* eslint-disable react/display-name */
import React from "react";
import { kbdStyles } from "./kbdStyles.css";

export const Kbd = React.forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & { children: React.ReactNode }
>(({ children, ...props }, ref) => {
  return (
    <kbd className={kbdStyles} {...props} ref={ref}>
      {children}
    </kbd>
  );
});
