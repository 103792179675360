import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { hotkeys } from "@puzzle/ui";
import useAppRouter from "lib/useAppRouter";
import { useCmdkStore } from "../cmdk/cmdkStore";
import { navigationShortcuts } from "../cmdk/shortcuts/Navigation";
import { helpShortcuts } from "../cmdk/shortcuts/Help";
import { Shortcut } from "../cmdk/shortcuts/types";
import { useShortcutActions } from "../ShortcutActionsProvider";

export const Hotkeys = () => {
  const router = useRouter();
  const { logout } = useAppRouter();
  const { toggleCmdk: toggleOpen } = useCmdkStore();
  const Actions = useShortcutActions();

  const shortcutsWithUniqueHotkeys: Shortcut[] = [...navigationShortcuts, ...helpShortcuts].filter(
    (shortcut, index, self) =>
      shortcut.hotkey?.trigger &&
      index === self.findIndex((t) => t.hotkey?.trigger === shortcut.hotkey?.trigger)
  );

  let globalShortcuts: Shortcut[] = []; // shortcuts that will fire anywhere
  let restrictedShortcuts: Shortcut[] = []; // shortcuts that won't fire when focused on an input field

  shortcutsWithUniqueHotkeys.forEach((shortcut) => {
    if (shortcut.hotkey?.shouldDisableOnInputs) {
      restrictedShortcuts.push(shortcut);
    } else {
      globalShortcuts.push(shortcut);
    }
  });

  const restrictedHotkeysDict: { [key: string]: () => void } = {};
  // Produce a dictionary of hotkeys to action functions
  restrictedShortcuts.forEach((shortcut) => {
    if (shortcut.actionOnSelect && shortcut.hotkey?.trigger) {
      const action = Actions[shortcut.actionOnSelect as keyof typeof Actions];
      restrictedHotkeysDict[shortcut.hotkey?.trigger] = action ? action : () => {};
    }
    if (shortcut.route && shortcut.hotkey?.trigger) {
      restrictedHotkeysDict[shortcut.hotkey?.trigger] = () => {
        if (shortcut.route) {
          router.push(shortcut.route);
        }
      };
    }
  });

  const globalHotkeysDict: { [key: string]: (e: KeyboardEvent) => void } = {};
  // Produce a dictionary of hotkeys to action functions
  globalShortcuts.forEach((shortcut) => {
    if (shortcut.actionOnSelect && shortcut.hotkey?.trigger) {
      const action = Actions[shortcut.actionOnSelect as keyof typeof Actions];
      globalHotkeysDict[shortcut.hotkey?.trigger] = action ? action : () => {};
    }
    if (shortcut.route && shortcut.hotkey?.trigger) {
      globalHotkeysDict[shortcut.hotkey?.trigger] = () => {
        if (shortcut.route) {
          router.push(shortcut.route);
        }
      };
    }
  });

  // Add the global hotkey for opening the cmd+k modal
  // This is not shown in the cmd+k modal itself
  globalHotkeysDict["Meta+k"] = (e: KeyboardEvent) => {
    // We removed e.preventDefault() because sometimes we want to fire a simultaneous cmd+k hotkey
    // See the hotkeys in TransactionsPaginated.tsx
    toggleOpen();
  };

  useEffect(() => {
    const unsubscribeRestrictedHotkeys = hotkeys(window, restrictedHotkeysDict);
    const unsubscribeGlobalHotkeys = hotkeys(
      window,
      globalHotkeysDict,
      false // FALSE means listen for it all the time. Default is TRUE which means don't listen when input is focused.
    );
    return () => {
      unsubscribeRestrictedHotkeys();
      unsubscribeGlobalHotkeys();
    };
  }, [router, logout, globalHotkeysDict, restrictedHotkeysDict]);

  return null;
};
