import React from "react";
import { Stack, Text } from "@puzzle/ui";
import { Exclamation } from "@puzzle/icons";
import { FORMATION_SPENDING_LIMIT } from "./util";

export const MonthlyExceededAlert = () => {
  return (
    <Stack
      css={{
        background: "$red100",
        borderRadius: "$2",
        color: "$red1000",
        padding: "$2",
        gap: "$1",
      }}
    >
      <Stack css={{ gap: "$2", flexDirection: "row" }}>
        <Exclamation size={16} />
        <Text css={{ textVariant: "$bodyM", fontWeight: "$bold" }}>
          Monthly Expense Allowance Exceeded
        </Text>
      </Stack>
      <Text variant="bodyM">
        It looks like you've exceeded the monthly expense allowance of $
        {FORMATION_SPENDING_LIMIT.toLocaleString()} for the Formation plan. <br />
        To access newly generated financial statements, reports, and insights, please upgrade to a
        paid plan.
      </Text>
    </Stack>
  );
};
