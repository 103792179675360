import React, { useMemo } from "react";

import { Clear, Message } from "@puzzle/icons";

import { color, Button, UnstyledButton, Text } from "ve";
import links from "lib/links";

import { useActiveCompany } from "components/companies";
import { BookkeeperPlan, MembershipRole } from "graphql/types";
import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import useSelf from "components/users/useSelf";

import { useGetUserRolesForCompanyQuery } from "components/dashboard/Users/graphql.generated";

import { CircledCheck } from "./CircledCheck";

import {
  rootStyleLarge,
  buttonRowStyle,
  buttonInsideStyle,
  smallRootStyle,
} from "./NeedHelpBanner.css";

const LARGE_VARIANT_DISMISSED = "monthly-checklist-hirebookkeeper";
const SIDE_VARIANT_DISMISSED = "monthly-checklist-hirebookkeeper-side";

const HireAnExpertButton = ({ fullWidth }: { fullWidth: boolean }) => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  return (
    <Button
      variant="primary"
      shape="pill"
      fullWidth={fullWidth}
      onClick={() => {
        window.open(links.hireAccountant, "_blank");
        upsertMessageSettings({
          variables: {
            input: {
              key: LARGE_VARIANT_DISMISSED,
              value: true,
            },
          },
        });
      }}
    >
      <div className={buttonInsideStyle}>
        <Message />
        Hire an expert
      </div>
    </Button>
  );
};

export const useShowNeedHelpBanner = () => {
  const { company } = useActiveCompany<true>();
  const { messageSettings } = useSelf();

  const { data, loading } = useGetUserRolesForCompanyQuery({
    variables: {
      companyId: company?.id,
    },
  });

  const largeVariantDismissed = messageSettings?.[LARGE_VARIANT_DISMISSED];
  const smallVariantDismissed = messageSettings?.[SIDE_VARIANT_DISMISSED];

  const memberships = data?.company?.memberships;
  const pendingInvitations = data?.company?.pendingInvitations;
  const hasBookkeeper =
    (memberships && memberships.some((m) => m.role === MembershipRole.Bookkeeper)) ||
    (pendingInvitations && pendingInvitations.some((m) => m.role === MembershipRole.Bookkeeper));

  return useMemo(
    () => ({
      showSmallNeedHelpBanner:
        largeVariantDismissed && // Show small variant once large one has been dismissed...
        !smallVariantDismissed && // ...but not when small variant has been dismissed
        company?.bookkeeperPlan !== BookkeeperPlan.HasBookkeeper &&
        !loading &&
        !hasBookkeeper,
      showLargeNeedHelpBanner:
        !largeVariantDismissed && // Show large variant until it has been dismissed
        company?.bookkeeperPlan !== BookkeeperPlan.HasBookkeeper &&
        !loading &&
        !hasBookkeeper,
      showHeaderNeedHelpButton: !loading && !hasBookkeeper,
    }),
    [loading, messageSettings, company, data]
  );
};

export const NeedHelpBannerLarge = () => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const handleClear = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: LARGE_VARIANT_DISMISSED,
          value: true,
        },
      },
    });
  };

  return (
    <div className={rootStyleLarge}>
      <UnstyledButton
        onClick={handleClear}
        css={{ position: "absolute", right: "20px", top: "20px", padding: "8px" }}
      >
        <Clear color={color.rhino200} size={24} />
      </UnstyledButton>

      <CircledCheck />

      <Text variant="bodyL" color="white">
        Close your books with ease
      </Text>
      <Text variant="headingM" color="gray300">
        Ever had to figure out all the things you needed to do to close out your accounting books
        for the month? Well no more.
      </Text>
      <div className={buttonRowStyle}>
        <HireAnExpertButton fullWidth={false} />
        <Button variant="secondary" shape="pill" onClick={handleClear}>
          I'll do it myself
        </Button>
      </div>
    </div>
  );
};

export const NeedHelpBannerSmall = () => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const handleClear = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SIDE_VARIANT_DISMISSED,
          value: true,
        },
      },
    });
  };

  return (
    <div className={smallRootStyle}>
      <UnstyledButton
        onClick={handleClear}
        css={{ position: "absolute", right: "12px", top: "12px", padding: "8px" }}
      >
        <Clear color={color.rhino200} size={24} />
      </UnstyledButton>

      <CircledCheck />
      <Text variant="bodyL" color="white">
        Need help?
      </Text>
      <Text variant="headingM" color="gray300">
        We’ve experts ready to help you close your books with ease.
      </Text>
      <HireAnExpertButton fullWidth={true} />
    </div>
  );
};
