import { useEffect } from "react";
import useAppRouter from "lib/useAppRouter";

export default function useEffectOnRouteChange(onClose: () => void) {
  const { router } = useAppRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", onClose);

    return () => {
      router.events.off("routeChangeStart", onClose);
    };
  }, [router, onClose]);
}
