import { useActiveCompany } from "components/companies";
import useSelf from "components/users/useSelf";
import { useRouter } from "next/router";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

export const SETTING_DASHBOARD_WELCOME_SEEN_KEY = "dashboard-welcome-seen";

export function useShowIntroTour() {
  const { messageSettings } = useSelf();
  const { company, membership } = useActiveCompany<true>();
  const router = useRouter();

  const isOwner = membership?.isOwner || false;

  const showTourOwner =
    !!company &&
    isOwner &&
    (!messageSettings[SETTING_DASHBOARD_WELCOME_SEEN_KEY] || Boolean(router.query.showTour)) &&
    !company.features.skipSetupChecklist;
    
  const showTourInvitee =
    !!company &&
    !!isPosthogFeatureFlagEnabled(FeatureFlag.PostOnboardModalV3Invitee) &&
    !isOwner &&
    (!messageSettings[SETTING_DASHBOARD_WELCOME_SEEN_KEY] || Boolean(router.query.showTour)) &&
    !company.features.skipSetupChecklist;

  return {
    showTourOwner,
    showTourInvitee
  };
}