import React from "react";
import { DialogProps, Dialog } from "@puzzle/ui";

import { ExplorePuzzlePane } from "./ExplorePuzzlePane";
import { AnalyticsTriggeredFrom } from "./analytics";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { zIndex } from "@puzzle/utils";

export interface ExplorePuzzleDialogProps extends DialogProps {
  onClose: () => void;
}

export const ExplorePuzzleDialog = ({ onClose, ...props }: ExplorePuzzleDialogProps) => {
  return (
    <Dialog
      size="large"
      {...props}
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
    >
      <Dialog.Title css={{ textVariant: "$headingS" }}>
        Explore Puzzle's features, select where to start:
      </Dialog.Title>

      <Dialog.Body css={{ padding: "0" }}>
        <ExplorePuzzlePane
          onClose={onClose}
          triggeredFrom={AnalyticsTriggeredFrom.leftSideNavBar}
        />
      </Dialog.Body>
    </Dialog>
  );
};
