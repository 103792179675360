import React from "react";
import {
  BarChart,
  Burn,
  Calculator2,
  Dashboard,
  Download,
  Dollar,
  Folder,
  NavChecklist,
  NavInbox,
  PeopleCard,
  Plug,
  Revenue,
  Settings,
  Vendors,
} from "@puzzle/icons";
import { CmdkShortcutId, OnSelectActions, ShortcutWithStaticId } from "./types";
import { Route } from "lib/routes";

/*
  Order is important in this array.
  More specific shortcuts should be placed before more general ones.
  E.g. A shortcut for changing a specific transaction should appear before a shortcut for creating a rule for all transactions.
  Remember, shortcuts will only be displayed if their urlPattern matches the current url (or it has no urlPattern).
*/

export const navigationShortcuts: ShortcutWithStaticId[] = [
  {
    id: CmdkShortcutId.GO_TO_INBOX,
    icon: <NavInbox size={12} />,
    description: "Go to inbox",
    route: Route.inbox,
    hotkey: {
      keys: ["G", "then", "I"],
      trigger: "g i",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_DASHBOARD,
    icon: <Dashboard size={12} />,
    description: "Go to dashboard",
    route: Route.home,
    hotkey: {
      keys: ["G", "then", "D"],
      trigger: "g d",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_MONTHLY_CHECKLIST,
    icon: <NavChecklist size={12} />,
    description: "Go to monthly checklist",
    route: Route.checklist,
    hotkey: {
      keys: ["G", "then", "M"],
      trigger: "g m",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_DOWNLOADS,
    icon: <Download size={12} />,
    description: "Go to downloads",
    route: Route.reports,
    hotkey: {
      keys: ["G", "then", "Y"],
      trigger: "g y",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_ACCOUNTING,
    icon: <Folder size={12} />,
    description: "Go to accounting",
    route: Route.accounting,
    hotkey: {
      keys: ["G", "then", "A"],
      trigger: "g a",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_GENERAL_LEDGER,
    icon: <Calculator2 size={12} />,
    description: "Go to general ledger",
    route: Route.generalLedger,
    hotkey: {
      keys: ["G", "then", "L"],
      trigger: "g l",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_RECONCILIATIONS,
    icon: <Calculator2 size={12} />,
    description: "Go to reconciliations",
    route: Route.ledgerReconciliations,
  },
  {
    id: CmdkShortcutId.GO_TO_MANUAL_JOURNAL_ENTRIES,
    icon: <Calculator2 size={12} />,
    description: "Go to manual journal entries",
    route: Route.manualJournals,
    hotkey: {
      keys: ["G", "then", "J"],
      trigger: "g j",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_FIXED_ASSETS,
    icon: <Calculator2 size={12} />,
    description: "Go to fixed assets",
    route: Route.fixedAssets,
    hotkey: {
      keys: ["G", "then", "F"],
      trigger: "g f",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_TRANSACTIONS,
    icon: <Dollar size={12} />,
    description: "Go to transactions",
    route: Route.transactions,
    hotkey: {
      keys: ["G", "then", "T"],
      trigger: "g t",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_SPENDING,
    icon: <Burn size={12} />,
    description: "Go to spending",
    route: Route.spending,
    hotkey: {
      keys: ["G", "then", "S"],
      trigger: "g s",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_REVENUE,
    icon: <Revenue size={12} />,
    description: "Go to revenue",
    route: Route.revenue,
    hotkey: {
      keys: ["G", "then", "R"],
      trigger: "g r",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_INVOICES,
    icon: <BarChart size={12} />,
    description: "Go to invoices",
    route: Route.invoices,
  },
  {
    id: CmdkShortcutId.GO_TO_AR_AGING,
    icon: <BarChart size={12} />,
    description: "Go to AR aging",
    route: Route.arAging,
  },
  {
    id: CmdkShortcutId.GO_TO_PEOPLE,
    icon: <PeopleCard size={12} />,
    description: "Go to people",
    route: Route.people,
    hotkey: {
      keys: ["G", "then", "E"],
      trigger: "g e",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_INTEGRATIONS,
    icon: <Plug size={12} />,
    description: "Go to integrations",
    route: Route.integrations,
    hotkey: {
      keys: ["G", "then", "C"],
      trigger: "g c",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_COMPANY_SETTINGS,
    icon: <Settings size={12} />,
    description: "Go to company settings",
    route: Route.companySettings,
    hotkey: {
      keys: ["G", "then", "P"],
      trigger: "g p",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_USER_SETTINGS,
    icon: <Settings size={12} />,
    description: "Go to user settings",
    route: Route.usersSettings,
    hotkey: {
      keys: ["G", "then", "P"],
      trigger: "g p",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_BILLS,
    icon: <Burn size={12} />,
    description: "Go to bills",
    route: Route.bills,
  },
  {
    id: CmdkShortcutId.GO_TO_VENDORS,
    icon: <Vendors size={12} />,
    description: "Go to vendors",
    route: Route.vendors,
    hotkey: {
      keys: ["G", "then", "V"],
      trigger: "g v",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.GO_TO_PREPAID_EXPENSES,
    icon: <Burn size={12} />,
    description: "Go to prepaid expenses",
    route: Route.prepaidExpenses,
  },
  {
    id: CmdkShortcutId.GO_TO_BILLING_PLANS,
    icon: <Settings size={12} />,
    description: "Go to billing & plans",
    route: Route.billing,
  },
  {
    id: CmdkShortcutId.GO_TO_NOTIFICATION_SETTINGS,
    icon: <Settings size={12} />,
    description: "Go to notification settings",
    route: Route.notificationSettings,
  },
  {
    id: CmdkShortcutId.GO_TO_RULES,
    icon: <Settings size={12} />,
    description: "Go to rules",
    actionOnSelect: OnSelectActions.GO_TO_RULES,
  },
  {
    id: CmdkShortcutId.GO_TO_AUTOMATION_SETTINGS,
    icon: <Settings size={12} />,
    description: "Go to automation settings",
    route: Route.automationSettings,
  },
  {
    id: CmdkShortcutId.GO_TO_POLICY_SETTINGS,
    icon: <Settings size={12} />,
    description: "Go to policy settings",
    route: Route.policySettings,
  },
  {
    id: CmdkShortcutId.GO_TO_CLASSIFICATIONS,
    icon: <Settings size={12} />,
    description: "Go to classifications",
    route: Route.classesSettings,
  },
  {
    id: CmdkShortcutId.GO_TO_AUDIT_LOG,
    icon: <Settings size={12} />,
    description: "Go to audit log",
    route: Route.auditLog,
  },
  {
    id: CmdkShortcutId.GO_TO_LOCKED_PERIOD,
    icon: <Settings size={12} />,
    description: "Go to locked period",
    actionOnSelect: OnSelectActions.GO_TO_LOCKED_PERIOD,
  },
  {
    id: CmdkShortcutId.GO_TO_CHART_OF_ACCOUNTS,
    icon: <Settings size={12} />,
    description: "Go to chart of accounts",
    actionOnSelect: OnSelectActions.GO_TO_CHART_OF_ACCOUNTS,
  },
  {
    id: CmdkShortcutId.GO_TO_ACCOUNTING_SETTINGS,
    icon: <Settings size={12} />,
    description: "Go to accounting settings",
    route: Route.automationSettings,
  },
  {
    id: CmdkShortcutId.GO_TO_HISTORICAL_BOOKS,
    icon: <Settings size={12} />,
    description: "Go to historical books",
    route: Route.historicalBooks,
  },
  {
    id: CmdkShortcutId.LOGOUT,
    icon: <Settings size={12} />,
    description: "Log out",
    actionOnSelect: OnSelectActions.LOGOUT,
    hotkey: {
      keys: ["Alt", "⇧", "Q"],
      trigger: "Alt+Shift+KeyQ",
      shouldDisableOnInputs: false, // false means: enable everywhere, even when focused on an input field
    },
  },
];
