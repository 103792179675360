import React, { PropsWithChildren } from "react";
import { Close } from "@puzzle/icons";
import { styled, CSS } from "./stitches";
import { UnstyledButton } from "./Button";

const Container = styled("div", {
  flex: "0 0 auto",
  display: "flex",
  alignItems: "center",
  minHeight: 40,
  px: "$2",
  py: "$1",
  fontVariant: "$body500",
  fontFamily: "$inter",
  fontSize: 12,

  a: {
    color: "inherit",
  },

  p: {
    margin: 0,
  },

  variants: {
    type: {
      info: {
        backgroundColor: "#3376E1",
        color: "$white",
        svg: {
          color: "$blue100",
        },
      },
      warning: {
        backgroundColor: "$yellow700",
        color: "$white",
        svg: {
          color: "$yellow100",
        },
      },
      error: {
        backgroundColor: "$red500",
        color: "$red",
        svg: {
          color: "$red100",
        },
      },
      neutral: {
        backgroundColor: "$green600",
        color: "$gray800",
        svg: {
          color: "$gray900",
        },
      },
    },
  },

  defaultVariants: {
    type: "neutral",
  },
});

const Content = styled("div", {
  flexGrow: "1",
});

export type SiteStatusMessageProps = {
  type?: "info" | "warning" | "error" | "neutral";
  onClose?: () => void;
  css?: CSS;
};

export const SiteStatusMessage = ({
  children,
  type = "neutral",
  onClose,
  ...props
}: PropsWithChildren<SiteStatusMessageProps>) => {
  // TODO: use appropriate icons

  return (
    <Container {...props} type={type}>
      {/* <Exclamation size={12} color="currentColor" css={{ marginRight: 6, marginLeft: 12 }} /> */}
      <Content>{children}</Content>

      {onClose && (
        <UnstyledButton
          onClick={onClose}
          css={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <Close size={14} color="currentColor" />
        </UnstyledButton>
      )}
    </Container>
  );
};
