import React from "react";

export const Efficieny1 = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="url(#paint0_linear_509_6020)"
        d="M15.704 9.308l-1.273-.839.013-.774 1.32-.753a.462.462 0 00.213-.547l-.523-1.592a.47.47 0 00-.466-.32l-1.512.061-.446-.636.574-1.33a.463.463 0 00-.156-.559l-1.372-.988h-.001a.473.473 0 00-.567.011l-1.125.882-.743-.254L9.328.358A.469.469 0 008.871 0H7.125a.47.47 0 00-.451.338l-.38 1.33-.629.212L4.63 1h.001a.472.472 0 00-.58-.024L2.56 2.037a.461.461 0 00-.166.539l.48 1.282-.327.452-1.368-.107a.468.468 0 00-.481.313l-.61 1.762c-.07.2.007.421.185.538l1.139.747-.008.537-1.18.72a.463.463 0 00-.204.526l.525 1.768v.001a.47.47 0 00.47.334l1.358-.06.342.505-.53 1.281a.464.464 0 00.144.543l1.407 1.091a.473.473 0 00.582-.002l1.064-.84.703.267.333 1.35v-.001c.049.202.228.346.438.354l1.68.063h.018a.469.469 0 00.456-.353l.28-1.131 1.12-.31.93.786h.002c.159.135.39.148.566.032l1.26-.84a.463.463 0 00.183-.532l-.486-1.452.48-.629 1.56.122v-.001a.47.47 0 00.483-.32l.504-1.535a.463.463 0 00-.187-.53zM11.287 8c0 1.795-1.474 3.256-3.287 3.256-1.811 0-3.286-1.461-3.286-3.256 0-1.795 1.475-3.256 3.286-3.256 1.812 0 3.287 1.461 3.287 3.256z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_509_6020"
          x1="16"
          x2="0"
          y1="-1"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Efficieny2 = () => {
  return (
    <svg width="17" height="17" fill="none" viewBox="0 0 17 17">
      <path
        fill="url(#paint0_linear_509_6275)"
        d="M8.5.5c-4.413 0-8 3.587-8 8s3.587 8 8 8 8-3.587 8-8-3.587-8-8-8zm3.15 10.206a.77.77 0 01-.64.337.893.893 0 01-.455-.135L8.045 9.14a.814.814 0 01-.337-.64V4.273a.79.79 0 111.584 0v3.823l2.172 1.532a.761.761 0 01.185 1.078z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_509_6275"
          x1="16.5"
          x2="0.5"
          y1="-0.5"
          y2="16.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#4432A1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Compliance = () => {
  return (
    <svg width="15" height="14" fill="none" viewBox="0 0 15 14">
      <path
        fill="url(#paint0_linear_509_6064)"
        fillRule="evenodd"
        d="M2.823 6.15H.86a.86.86 0 00-.86.862v6.042c0 .237.096.453.252.607l.002.002a.856.856 0 00.606.254h1.962a.848.848 0 00.606-.254l.002-.002a.86.86 0 00.253-.607l-.001-6.042a.851.851 0 00-.253-.607v-.002a.849.849 0 00-.607-.253z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint1_linear_509_6064)"
        fillRule="evenodd"
        d="M5.841 5.314c-.466.68-.952 1.344-1.458 1.99l-.091.117v5.28l.197.127c.451.29.904.53 1.359.72.46.193.93.341 1.41.443l.047.009h5.614c.344 0 .659-.14.884-.368v-.002a1.25 1.25 0 00-.153-1.9c.25-.048.476-.173.65-.347l.003.002a1.25 1.25 0 000-1.772l.002-.002a1.307 1.307 0 00-.198-.161 1.25 1.25 0 00.525-2.086h-.001a1.244 1.244 0 00-.5-.307 1.25 1.25 0 00.212-1.94l-.001.001a1.246 1.246 0 00-.885-.368H9.64c.074-.328.144-.653.21-.974.098-.48.181-.92.248-1.322l.003-.02C10.26 1.066 9.633.276 8.216.06L7.819 0l-.083.395c-.077.37-.17.816-.277 1.338-.12.587-.222 1.101-.31 1.54a40.782 40.782 0 01-1.308 2.04z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_509_6064"
          x1="3.684"
          x2="-2.57"
          y1="5.665"
          y2="8.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_509_6064"
          x1="15"
          x2="1.271"
          y1="-0.875"
          y2="10.283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Other = () => {
  return (
    <svg width="14" height="4" fill="none" viewBox="0 0 14 4">
      <circle cx="2" cy="2" r="2" fill="url(#paint0_linear_509_6079)"></circle>
      <circle cx="7" cy="2" r="2" fill="url(#paint1_linear_509_6079)"></circle>
      <circle cx="12" cy="2" r="2" fill="url(#paint2_linear_509_6079)"></circle>
      <defs>
        <linearGradient
          id="paint0_linear_509_6079"
          x1="4"
          x2="0"
          y1="-0.25"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_509_6079"
          x1="9"
          x2="5"
          y1="-0.25"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_509_6079"
          x1="14"
          x2="10"
          y1="-0.25"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Insights = () => {
  return (
    <svg width="16" height="12" fill="none" viewBox="0 0 16 12">
      <path
        fill="url(#paint0_linear_509_6148)"
        stroke="#AB7DE3"
        d="M2.865 6.25h0c1.33 0 2-.907 2.509-1.599l.04-.053c.55-.747.953-1.247 1.775-1.247.415 0 .832.163 1.31.406.106.053.216.113.332.174.368.197.78.416 1.18.544.567.181 1.198.213 1.845-.176.611-.368 1.17-1.074 1.727-2.192.548-1.103 1.38-1.5 2.177-1.587a3.839 3.839 0 011.74.236V12.5h-19V8.356c.065-.014.124-.034.175-.054.18-.074.362-.198.522-.317.114-.084.24-.183.37-.285l.173-.137c.784-.612 1.793-1.312 3.125-1.312z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_509_6148"
          x1="-2"
          x2="1.302"
          y1="3.763"
          y2="18.773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Fundraise = () => {
  return (
    <svg width="19" height="12" fill="none" viewBox="0 0 19 12">
      <path
        fill="url(#paint0_linear_509_6172)"
        d="M16.714.984H1.954c-.893 0-1.62.726-1.62 1.62v7.658c0 .893.727 1.62 1.62 1.62h14.76c.893 0 1.62-.727 1.62-1.62V2.604c0-.894-.727-1.62-1.62-1.62zm.22 7.262a.494.494 0 01-.668.458c-.648-.254-1.237.396-1.002.992.125.31-.128.675-.461.664H3.865c-.331.01-.587-.354-.461-.664.235-.596-.354-1.246-1.002-.992-.31.129-.678-.123-.667-.458V4.62a.494.494 0 01.667-.458.77.77 0 001.002-.992c-.125-.31.128-.675.461-.664h10.938c.331-.01.587.354.461.664a.77.77 0 001.001.992c.308-.129.68.123.668.458v3.626z"
      ></path>
      <path
        fill="url(#paint1_linear_509_6172)"
        d="M14.23 3.488H4.438a1.763 1.763 0 01-1.722 1.711v2.468c.936.02 1.696.776 1.722 1.712h9.792a1.763 1.763 0 011.721-1.712V5.199a1.763 1.763 0 01-1.721-1.711zm-4.896 5.2a2.257 2.257 0 01-2.255-2.255c.113-2.987 4.397-2.986 4.51 0a2.257 2.257 0 01-2.255 2.255z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_509_6172"
          x1="18.334"
          x2="8.941"
          y1="0.303"
          y2="16.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_509_6172"
          x1="15.951"
          x2="11.745"
          y1="3.119"
          y2="13.161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Accuracy = () => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="url(#paint0_linear_509_6307)"
        d="M6.984 9.069a1.495 1.495 0 010-2.12 1.495 1.495 0 012.12 0 1.495 1.495 0 010 2.12c-.587.588-1.532.606-2.12 0zM0 8.018c0-.553.445-.998.998-.998h1.104A5.925 5.925 0 013.76 3.777 5.926 5.926 0 017.002 2.12V1.016C7.02.446 7.465 0 8.018 0c.552 0 .998.445.998.998v1.104a5.925 5.925 0 013.242 1.657 5.926 5.926 0 011.657 3.243h1.105c.552 0 .998.446.998.998a.996.996 0 01-.998.998h-1.105a5.926 5.926 0 01-1.657 3.243 5.926 5.926 0 01-3.242 1.657v1.104a.996.996 0 01-.998.998.996.996 0 01-.998-.998v-1.104a5.925 5.925 0 01-3.243-1.657A5.926 5.926 0 012.12 8.998H1.016C.446 9.033 0 8.588 0 8.018zm5.22 2.833a4.005 4.005 0 002.833 1.176c1.07 0 2.085-.41 2.833-1.176a4.044 4.044 0 000-5.702 4.005 4.005 0 00-2.833-1.176A3.949 3.949 0 005.22 5.167c-1.603 1.568-1.603 4.134 0 5.684z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_509_6307"
          x1="16.018"
          x2="0.019"
          y1="-1"
          y2="16.018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3"></stop>
          <stop offset="1" stopColor="#6B58CD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Support = () => {
  return (
    <svg width="15" height="14" fill="none" viewBox="0 0 15 14">
      <path
        fill="#7A5AB9"
        d="M11.7 5.31v.483a.475.475 0 00-.467.483v4.827c0 .234.16.429.374.474a1.167 1.167 0 01-1.074.733H9.27a1.167 1.167 0 00-1.07-.724h-.933c-.645 0-1.167.54-1.167 1.207S6.622 14 7.267 14H8.2c.478 0 .89-.298 1.07-.724h1.263c1.013 0 1.859-.742 2.057-1.73 1.086-.205 1.91-1.19 1.91-2.374v-.965c0-1.168-.802-2.142-1.867-2.366v-.53C12.633 2.377 10.335 0 7.5 0S2.367 2.378 2.367 5.31v.531C1.302 6.065.5 7.04.5 8.207v.965c0 1.333 1.045 2.414 2.333 2.414H3.3a.475.475 0 00.467-.483V6.276a.475.475 0 00-.467-.483V5.31C3.3 2.91 5.18.966 7.5.966c2.32 0 4.2 1.945 4.2 4.344z"
      ></path>
    </svg>
  );
};
