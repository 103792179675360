import React, { useState } from "react";
import { Command } from "cmdk";
import {
  cmdKContainerStyles,
  cmdKFooterStyles,
  cmdKItemIconContainerStyles,
  cmdKItemLabelStyles,
  cmdKOverlayStyle,
  cmdKTagStyles,
} from "./cmdk.css";
import { ChevronRight, GoTo } from "@puzzle/icons";
import { Text } from "ve";
import { keysContainer } from "../ShortcutsModal/shortcutsModal.css";
import { Kbd } from "ve/Kbd/Kbd";
import { useRouter } from "next/router";
import { getClosestStaticRoute, getRouteTitle, isDynamicRoute, Route } from "lib/routes";
import { Tag } from "@puzzle/ui";
import useAppRouter from "lib/useAppRouter";
import { useActiveCompany } from "components/companies";
import { InactiveCompanyFragment } from "components/companies/graphql.generated";
import Analytics from "lib/analytics";
import { commandScore } from "./utils";
import { useCmdkStore } from "./cmdkStore";
import { SHORTCUTS_GROUPS } from "./shortcuts";
import { useShortcutActions } from "../ShortcutActionsProvider";
import { Shortcut } from "./shortcuts/types";

// Keys to ignore and render the word entirely
// outside a kbd
const IGNORED_KEY = ["then", "or"];

const shouldIgnoreKey = (key: string) => {
  return IGNORED_KEY.includes(key);
};

const Item = ({
  icon,
  description,
  hotkey,
  keywords,
  onSelect,
}: Shortcut & { onSelect: () => void }) => {
  return (
    <Command.Item onSelect={onSelect} keywords={keywords}>
      <div className={cmdKItemIconContainerStyles}>{icon ? icon : <ChevronRight size={12} />}</div>
      <Text className={cmdKItemLabelStyles}>{description}</Text>
      <div className={keysContainer}>
        {hotkey?.keys.map((key, keyIndex) =>
          shouldIgnoreKey(key) ? key : <Kbd key={`${description}-${keyIndex}`}>{key}</Kbd>
        )}
      </div>
    </Command.Item>
  );
};

export const CmdK = () => {
  const { companies, setActiveCompanyId } = useActiveCompany<true>();
  const router = useRouter();
  const { getCurrentRoute } = useAppRouter();
  const { isCmdkOpen: open, setIsCmdkOpen: setOpen } = useCmdkStore();
  const Actions = useShortcutActions();
  // hacky way to avoid selecting the first one on open
  const [value, setValue] = useState<string>("none");

  const handleSelect = (onSelect: keyof typeof Actions) => {
    if (Actions[onSelect]) Actions[onSelect]?.();
  };

  // Copy paste from Company Menu, but it might be helpful
  // to abstract this into a custom hook.
  const setCompany = (company: InactiveCompanyFragment) => {
    Analytics.companyChanged({
      companyId: company.id,
    });
    setActiveCompanyId(company.id);
    if (isDynamicRoute(router.pathname)) {
      router.replace(getClosestStaticRoute(router.pathname));
    }
  };

  const sortedCompanies = [...companies].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setValue("none");
    }
    setOpen(open);
  };

  // Given the current route, return the title of the page.
  // Gets the first part of the pathname and returns a title.
  // Example: /inbox/updates -> "inbox"
  const tagContent = getRouteTitle(getCurrentRoute());

  return (
    <Command.Dialog
      open={open}
      onOpenChange={handleOpenChange}
      value={value}
      onValueChange={setValue}
      filter={commandScore}
    >
      <div className={cmdKOverlayStyle} onClick={() => handleOpenChange(false)} />
      <div className={cmdKContainerStyles}>
        {/* This is a tag that displays over the search input, showing what page you are on. */}
        <div className={cmdKTagStyles}>
          {tagContent && <Tag css={{ borderRadius: "$1" }}>{tagContent}</Tag>}
        </div>
        <Command.Input placeholder="Type a command or search" />
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>
          {SHORTCUTS_GROUPS.map((group) => (
            <Command.Group heading={group.title} key={`kbd-${group.id}`}>
              {group.shortcuts.map((shortcut) => (
                <Item
                  icon={shortcut.icon}
                  description={shortcut.description}
                  hotkey={shortcut.hotkey}
                  key={`kbd-${group.id}-${shortcut.id}`}
                  keywords={shortcut.keywords}
                  onSelect={() => {
                    if (shortcut.actionOnSelect) {
                      handleSelect(shortcut.actionOnSelect as keyof typeof Actions);
                    }
                    if (shortcut.route) {
                      router.push(shortcut.route);
                    }
                    setOpen(false);
                  }}
                />
              ))}
            </Command.Group>
          ))}
          {companies.length > 1 && (
            <Command.Group heading="Switch company">
              {sortedCompanies.map((company) => (
                <Item
                  key={`switch-company-${company.id}`}
                  icon={<GoTo size={12} />}
                  description={company.name}
                  onSelect={() => {
                    setCompany(company);
                  }}
                />
              ))}
            </Command.Group>
          )}
        </Command.List>
        <div className={cmdKFooterStyles}>
          <div className={keysContainer}>
            <Kbd>↑</Kbd>
            <Kbd>↓</Kbd>
            <div>Navigate items</div>
          </div>
          <div className={keysContainer}>
            <Kbd>Esc</Kbd>
            <div>Close</div>
          </div>
        </div>
      </div>
    </Command.Dialog>
  );
};
