import { create } from "zustand";

interface MoreAboutYouModalStore {
  isMoreAboutYouModalShown: boolean;
  hideMoreAboutYouModal: () => void;
}

export const useMoreAboutYouModalStore = create<MoreAboutYouModalStore>()((set) => ({
  isMoreAboutYouModalShown: true,
  hideMoreAboutYouModal: () => set({ isMoreAboutYouModalShown: false }),
}));
