import React from "react";
import { Dialog, Stack } from "@puzzle/ui";
import UpgradeButton from "components/featureGate/UpgradeButton";
import { color, IconButton, vars, Text } from "ve";
import { ChartRising3, Close, GradientDollar, SparkleOutlined } from "@puzzle/icons";
import ceoNoteImage from "public/images/trialExpired/ceo-note.png";
import Image from "next/image";
import { useTrialExpiredModal } from "../useTrialExpiredModal";
import { ReactElement } from "react-markdown/lib/react-markdown";

function Item({ icon, content }: { icon: ReactElement; content: string }) {
  return (
    <Stack direction="horizontal" css={{ alignItems: "center" }}>
      <Stack
        css={{
          background: "rgba(0, 0, 0, 0.16)",
          padding: vars.space["1"],
          height: vars.space["5"],
          width: vars.space["5"],
          borderRadius: vars.radii["2"],
          justifyContent: "center",
        }}
      >
        {icon}
      </Stack>
      <Text variant="body">{content}</Text>
    </Stack>
  );
}
export const TrialExpiredModalV2 = () => {
  const { isModalVisible, handleOpenChange } = useTrialExpiredModal();
  return (
    <Dialog width={820} open={isModalVisible} onOpenChange={handleOpenChange}>
      <Dialog.Body
        css={{
          padding: "0",
          backgroundColor: vars.colors.mauve900,
          position: "relative",
        }}
      >
        <Stack direction="horizontal" gap="0">
          <Stack direction="vertical" css={{ width: "50%", justifyContent: "center" }}>
            <Image src={ceoNoteImage} alt="spending" />
          </Stack>
          <Stack
            direction="vertical"
            gap="2"
            css={{ width: "50%", background: color.gray700, padding: vars.space["2h"] }}
          >
            <Stack direction="horizontal">
              <Text variant="heading2" weight="bold" color="gray400">
                Your Advanced plan trial has ended.
              </Text>
              <IconButton onClick={() => handleOpenChange(false)}>
                <Close size={24} />
              </IconButton>
            </Stack>
            <Text variant="body">
              We hope you have enjoyed the features on the Advanced plan, including:
            </Text>
            <Stack direction="vertical" css={{ alignItems: "left" }}>
              <Item icon={<ChartRising3 />} content="financial metrics" />
              <Item icon={<SparkleOutlined />} content="automation and AI tools" />
              <Item icon={<GradientDollar />} content="unlimited expenses" />
            </Stack>
            <Text variant="body">Choose a plan that works for your company today!</Text>
            <Text variant="body">
              Not ready yet? No worries, we'll place you on our free Formation plan.
            </Text>
            <UpgradeButton
              title="View Plans"
              showIcon={false}
              css={{ width: "100%" }}
              onClick={() => handleOpenChange(false)}
            />
          </Stack>
        </Stack>
      </Dialog.Body>
    </Dialog>
  );
};
