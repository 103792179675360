import React, { createContext, useContext, ReactNode } from "react";
import { ActionDict } from "./cmdk/shortcuts/types";
import { useRouter } from "next/router";
import { Route } from "lib/routes";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useShortcutsModal } from "./ShortcutsModal/useShortcutsModal";
import { useIntercom } from "react-use-intercom";
import { useCmdkStore } from "./cmdk/cmdkStore";
import useAppRouter from "lib/useAppRouter";

const isNavV2 = isPosthogFeatureFlagEnabled(FeatureFlag.NavigationV2);

interface ActionsContextProps {
  Actions: ActionDict;
}

const ActionsContext = createContext<ActionsContextProps | undefined>(undefined);

export const ShortcutActionsProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { show: showIntercom } = useIntercom();
  const { toggleShortcutsModal } = useShortcutsModal();
  const { toggleCmdk: toggleOpen } = useCmdkStore();
  const { logout } = useAppRouter();

  const Actions: ActionDict = {
    OPEN_SHORTCUTS: (e) => {
      toggleShortcutsModal();
    },
    OPEN_HELP_WINDOW: (e) => {
      window.open("https://help.puzzle.io", "_blank");
    },
    SHOW_INTERCOM: (e) => {
      e?.preventDefault();
      showIntercom();
    },
    TOGGLE_CMDK_MODAL: (e) => {
      e?.preventDefault();
      toggleOpen();
    },
    GO_TO_RULES: (e) => {
      router.push(isNavV2 ? Route.rulesSettings : Route.rules);
    },
    GO_TO_LOCKED_PERIOD: (e) => {
      router.push(isNavV2 ? Route.lockedPeriodSettings : Route.lockedPeriod);
    },
    GO_TO_CHART_OF_ACCOUNTS: (e) => {
      router.push(isNavV2 ? Route.chartOfAccountsSettings : Route.chartOfAccounts);
    },
    LOGOUT: (e) => {
      logout();
    },
  };

  return <ActionsContext.Provider value={{ Actions }}>{children}</ActionsContext.Provider>;
};

export const useShortcutActions = () => {
  const context = useContext(ActionsContext);
  if (!context) {
    throw new Error("useShortcutActions must be used in a ShortcutActionsProvider");
  }
  return context.Actions;
};
