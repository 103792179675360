import React, { useState } from "react";
import { Close, SpinnerStatic } from "@puzzle/icons";
import { Button, IconButton, Text, colors } from "@puzzle/ui";
import useMonetization from "components/monetization/useMonetization";
import { Box, S, color, vars } from "ve";

interface ITrialReminderProps {
  daysLeft: number;
  minimized: boolean;
}

const TrialReminder = ({ daysLeft, minimized }: ITrialReminderProps) => {
  const { showUpgradeModal } = useMonetization();
  const [showReminder, setShowReminder] = useState(true);

  if (!showReminder) return null;

  if (minimized) {
    return (
      <Box css={{ marginLeft: S["1"], marginBottom: S["2"] }}>
        <IconButton onClick={() => showUpgradeModal()}>
          <SpinnerStatic />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "column",
        padding: S["2"],
        gap: S["1h"],
        background: color.mauve650,
        borderRadius: vars.radii[2],
      }}
    >
      <Box css={{ display: "flex", justifyContent: "space-between" }}>
        <Text variant="headingM" color="white">
          Trial reminder
        </Text>
        <IconButton
          onClick={() => {
            setShowReminder(false);
          }}
        >
          <Close size={18} color={colors.gray500} />
        </IconButton>
      </Box>
      <Text variant="bodyS" color="gray400">
        {daysLeft >= 1 ? (
          <>
            You have{" "}
            <Text color="gray200">
              {daysLeft} {daysLeft !== 1 ? "days" : "day"} left
            </Text>{" "}
            to enjoy premium features.
          </>
        ) : (
          <>You are downgraded to the free plan. Upgrade to continue using the premium features.</>
        )}
      </Text>
      <Button variant="primary" fullWidth size="small" onClick={() => showUpgradeModal()}>
        Upgrade
      </Button>
    </Box>
  );
};

export { TrialReminder };
