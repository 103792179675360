import React from "react";
import { Drawer, IconButton } from "@puzzle/ui";
import {
  containerStyles,
  groupTitleStyles,
  headerStyles,
  keyGroupStyles,
  keysContainer,
  shortkeyDescriptionStyles,
  shortkeyRowStyles,
} from "./shortcutsModal.css";
import { Close } from "@puzzle/icons";
import { Kbd } from "ve/Kbd/Kbd";
import { useShortcutsModal } from "./useShortcutsModal";
import { generalShortcuts } from "../cmdk/shortcuts/General";
import { navigationShortcuts } from "../cmdk/shortcuts/Navigation";
import { helpShortcuts } from "../cmdk/shortcuts/Help";

// A list of ONLY SHORTCUTS WITH HOTKEYS to display in the drawer
const SHORTCUT_LIST = [
  {
    title: "General",
    id: "general",
    shortcuts: generalShortcuts.filter((shortcut) => shortcut.hotkey),
  },
  {
    title: "Navigation",
    id: "navigation",
    shortcuts: navigationShortcuts.filter((shortcut) => shortcut.hotkey),
  },
  {
    title: "Help",
    id: "help",
    shortcuts: helpShortcuts.filter((shortcut) => shortcut.hotkey),
  },
];

// Keys to ignore and render the word entirely
// outside a kbd
const IGNORED_KEY = ["then", "or"];

const shouldIgnoreKey = (key: string) => {
  return IGNORED_KEY.includes(key);
};

export const ShortcutsModal = () => {
  const { isVisible, hideShortcutsModal } = useShortcutsModal();

  return (
    <Drawer
      css={{ zIndex: "2147483003", width: "400px" }} // Thanks Intercom
      open={isVisible}
      onOpenChange={(open) => {
        if (!open) hideShortcutsModal();
      }}
    >
      <div className={containerStyles}>
        <div className={headerStyles}>
          Keyboard Shortcuts
          <IconButton onClick={() => hideShortcutsModal()}>
            <Close fill="currentColor" />
          </IconButton>
        </div>
        {SHORTCUT_LIST.map((group, index) => (
          <div key={`${group.title}-${index}`} className={keyGroupStyles}>
            <div className={groupTitleStyles}>{group.title}</div>
            {group.shortcuts.map((shortcut, shortcutIndex) => (
              <div className={shortkeyRowStyles} key={`${group.title}-${index}-${shortcutIndex}`}>
                <div className={shortkeyDescriptionStyles}>{shortcut.description}</div>
                <div className={keysContainer}>
                  {shortcut.hotkey?.keys.map((key, keyIndex) =>
                    shouldIgnoreKey(key) ? (
                      key
                    ) : (
                      <Kbd key={`${group.title}-${index}-${shortcutIndex}-${keyIndex}`}>{key}</Kbd>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Drawer>
  );
};
