import React from "react";

import ToggleGroup from "components/common/ToggleGroup";

import { SubscriptionBillingCycle } from "../types";
import useMonetization from "../useMonetization";

const SubscriptionCycleSwitchComponent = ({
  cycle,
  setCycle,
}: {
  cycle: SubscriptionBillingCycle;
  setCycle: (cycle: SubscriptionBillingCycle) => void;
}) => {
  return (
    <ToggleGroup.Root
      type="single"
      onValueChange={(value) => {
        if (!value) return;

        setCycle(value as SubscriptionBillingCycle);
      }}
      value={cycle}
      css={{ width: "300px" }}
    >
      <ToggleGroup.Item
        key={SubscriptionBillingCycle.Yearly}
        value={SubscriptionBillingCycle.Yearly}
        title="Yearly"
      >
        Yearly • Save 15%
      </ToggleGroup.Item>
      <ToggleGroup.Item
        key={SubscriptionBillingCycle.Monthly}
        value={SubscriptionBillingCycle.Monthly}
        title="Monthly"
      >
        Monthly
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
};

export const SubscriptionCycleSwitch = () => {
  const { selectedCycle, setSelectedCycle } = useMonetization();

  return (
    <SubscriptionCycleSwitchComponent
      cycle={selectedCycle || SubscriptionBillingCycle.Yearly}
      setCycle={setSelectedCycle}
    />
  );
};
