import React from "react";
import { styled, CSS } from "@puzzle/ui";

const Title = styled("div", {
  fontSize: "10px",
  lineHeight: "14px",
  letterSpacing: "1px",
  textTransform: "uppercase",
  padding: "0 $1 $1h",
  margin: "0",
  color: "$gray500",
  whiteSpace: "nowrap",
});

const Root = styled("div", {
  width: "100%",
  margin: "0",
  padding: "$1h 0",
  position: "relative",
  listStyle: "none",

  variants: {
    minimized: {
      true: {
        [`${Title}`]: {
          opacity: "0",
          userSelect: "none",
        },
      },
    },
  },
});

const NavigationSection = ({
  title,
  children,
  ...props
}: React.PropsWithChildren<{
  title?: string;
  minimized?: boolean;
  css?: CSS;
}>) => {
  return (
    <Root {...props}>
      {title && <Title>{title}</Title>}

      {children}
    </Root>
  );
};

export default NavigationSection;
