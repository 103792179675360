import React from "react";
import { Contact, Docs, Keyboard, NavAccountant, SendFeedback } from "@puzzle/icons";
import { Route } from "lib/routes";
import { CmdkShortcutId, OnSelectActions, ShortcutWithStaticId } from "./types";
import { Colors } from "@puzzle/icons/src/lib/colors";

/*
  Order is important in this array.
  More specific shortcuts should be placed before more general ones.
  E.g. A shortcut for changing a specific transaction should appear before a shortcut for creating a rule for all transactions.
  Remember, shortcuts will only be displayed if their urlPattern matches the current url (or it has no urlPattern).
*/

export const helpShortcuts: ShortcutWithStaticId[] = [
  {
    id: CmdkShortcutId.KEYBOARD_SHORTCUTS,
    icon: <Keyboard />,
    description: "Keyboard shortcuts",
    keywords: ["help"],
    actionOnSelect: OnSelectActions.OPEN_SHORTCUTS,
    hotkey: {
      keys: ["⇧", "/"],
      trigger: "Shift+Slash",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.ASK_AN_ACCOUNTANT,
    icon: <NavAccountant size={12} />,
    description: "Ask an accountant",
    keywords: ["help"],
    route: Route.askAccountant,
    hotkey: {
      keys: ["⇧", "A"],
      trigger: "Shift+A",
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.HELP_DOCS,
    icon: <Docs />,
    description: "Help docs ↗",
    keywords: ["help"],
    actionOnSelect: OnSelectActions.OPEN_HELP_WINDOW,
  },
  {
    id: CmdkShortcutId.SEND_FEEDBACK,
    icon: <SendFeedback />,
    description: "Send feedback",
    keywords: ["help"],
    actionOnSelect: OnSelectActions.SHOW_INTERCOM,
    hotkey: {
      keys: ["⌘", "⇧", "H"],
      trigger: "Meta+Shift+H",
      shouldDisableOnInputs: false, // false means: enable everywhere, even when focused on an input field
    },
  },
  {
    id: CmdkShortcutId.CONTACT,
    icon: <Contact color={Colors.ActiveGrey} />,
    description: "Contact",
    keywords: ["help"],
    actionOnSelect: OnSelectActions.SHOW_INTERCOM,
    hotkey: {
      keys: ["⌘", "⇧", "H"],
      trigger: "Meta+Shift+H",
      shouldDisableOnInputs: false, // false means: enable everywhere, even when focused on an input field
    },
  },
];
