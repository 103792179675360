import { ScrollArea } from "@puzzle/ui";
import { styled } from "@stitches/react";

export const Body = styled(ScrollArea.Viewport, {
  padding: "$3",
});

export const Header = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  padding: "$1h $2 $1h $3",
  alignItems: "center",
  fontSize: "$bodyM",
  fontWeight: "$bold",
  color: "$gray500",
  borderBottom: "1px solid $mauve550",
  textVariant: "$bodyM",
});

export const HeaderTitle = styled("div", {
  display: "flex",
  alignItems: "center",
  flex: "1",
  gap: "$1",
});

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

export const ScrollAnchor = styled("div", {
  marginTop: -10,
  height: 10,
  zIndex: -1,
});
