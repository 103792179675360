import React from "react";
import { Keyboard } from "@puzzle/icons";
import { CmdkShortcutId, ShortcutWithStaticId } from "./types";

/*
  Order is important in this array.
  More specific shortcuts should be placed before more general ones.
  E.g. A shortcut for changing a specific transaction should appear before a shortcut for creating a rule for all transactions.
  Remember, shortcuts will only be displayed if their urlPattern matches the current url (or it has no urlPattern).
*/

export const generalShortcuts: ShortcutWithStaticId[] = [
  {
    id: CmdkShortcutId.GO_BACK,
    icon: <Keyboard />,
    description: "Back",
    hotkey: {
      keys: ["ESC"],
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.OPEN_SEARCH,
    icon: <Keyboard />,
    description: "Open search",
    hotkey: {
      keys: ["/"],
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.PREVIOUS,
    icon: <Keyboard />,
    description: "Previous",
    hotkey: {
      keys: ["↑", "or", "K"],
      shouldDisableOnInputs: true,
    },
  },
  {
    id: CmdkShortcutId.NEXT,
    icon: <Keyboard />,
    description: "Next",
    hotkey: {
      keys: ["↓", "or", "J"],
      shouldDisableOnInputs: true,
    },
  },
];
