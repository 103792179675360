import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SiteStatusMessagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SiteStatusMessagesQuery = { __typename?: 'Query', siteStatusMessages: Array<{ __typename?: 'SiteStatusMessage', id: string, type: Types.SiteStatusMessageType, message: string }> };


export const SiteStatusMessagesDocument = gql`
    query siteStatusMessages {
  siteStatusMessages {
    id
    type
    message
  }
}
    `;

/**
 * __useSiteStatusMessagesQuery__
 *
 * To run a query within a React component, call `useSiteStatusMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteStatusMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteStatusMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteStatusMessagesQuery(baseOptions?: Apollo.QueryHookOptions<SiteStatusMessagesQuery, SiteStatusMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteStatusMessagesQuery, SiteStatusMessagesQueryVariables>(SiteStatusMessagesDocument, options);
      }
export function useSiteStatusMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteStatusMessagesQuery, SiteStatusMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteStatusMessagesQuery, SiteStatusMessagesQueryVariables>(SiteStatusMessagesDocument, options);
        }
export type SiteStatusMessagesQueryHookResult = ReturnType<typeof useSiteStatusMessagesQuery>;
export type SiteStatusMessagesLazyQueryHookResult = ReturnType<typeof useSiteStatusMessagesLazyQuery>;
export type SiteStatusMessagesQueryResult = Apollo.QueryResult<SiteStatusMessagesQuery, SiteStatusMessagesQueryVariables>;