import React from "react";
import { Dialog, DialogProps, Button, Stack } from "@puzzle/ui";

export const AccrualAutomationModal = ({ onOpenChange, ...props }: DialogProps) => {
  return (
    <Dialog size="small" onOpenChange={onOpenChange} {...props}>
      <Dialog.Title>Accrual automation</Dialog.Title>
      <Dialog.Body>
        <Stack gap="2">
          <div>
            Accrual automation is measured by the number of accrual transactions that are
            automatically scheduled. This ensures that you only pay for the value that Puzzle
            delivers – increasing your efficiency through automation.
          </div>
          <div>
            An accrual automation is counted when deferred revenue, prepaid, fixed asset
            transactions are automatically posted by Puzzle.
          </div>
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={() => onOpenChange?.(false)}>OK</Button>
      </Dialog.Footer>
    </Dialog>
  );
};
