import Item from "./Item";
import Section from "./Section";

// TODO replace with radix
// https://www.radix-ui.com/docs/primitives/components/navigation-menu
const Navigation = {
  Item,
  Section,
};

export default Navigation;
