import React, { useMemo } from "react";
import { colors } from "@puzzle/ui";
import { Exclamation } from "@puzzle/icons";
import { IntegrationConnectionFragment, IntegrationConnectionStatus } from "graphql/types";
import { useFinancialInstitutions } from "../../../../integrations";
import {
  getIntegrationConnectionForCategory,
  integrationCategoriesWithoutAccounts,
} from "../../../../integrations/setup/utils";

const IntegrationStatusIcon = ({ minimized = false }: { minimized: boolean }) => {
  const { financialInstitutionGroups, integrationConnections } = useFinancialInstitutions();

  const activeIntegrationConnections = useMemo(() => {
    let result: IntegrationConnectionFragment[] = [];

    // Get integration connections for financial institutions with active accounts
    financialInstitutionGroups.forEach((financialInstitutions, category) => {
      financialInstitutions.forEach((financialInstitution) => {
        const hasActiveAccounts = financialInstitution.accounts.some(
          (account) => account.status === IntegrationConnectionStatus.Ok && !account.archivedAt
        );

        if (hasActiveAccounts) {
          result = result.concat(financialInstitution.integrationConnections);
        }
      });
    });

    // Also check integration connections for financial institutions without accounts
    integrationCategoriesWithoutAccounts.forEach((category) => {
      const connections = getIntegrationConnectionForCategory(category, integrationConnections);

      if (connections.length > 0) {
        result.push(...connections);
      }
    });

    return result;
  }, [financialInstitutionGroups, integrationConnections]);

  const disconnectionStatus = useMemo(
    () =>
      activeIntegrationConnections.some(
        (ic) => ic.status === IntegrationConnectionStatus.Disconnected && ic.errorCode
      ),
    [activeIntegrationConnections]
  );

  const outageStatus = useMemo(
    () =>
      activeIntegrationConnections.some((ic) => ic.status === IntegrationConnectionStatus.Error),
    [activeIntegrationConnections]
  );

  const content = useMemo(() => {
    if (disconnectionStatus) {
      return <Exclamation fill={colors.red500} size={minimized ? 12 : 16} />;
    } else if (outageStatus && !disconnectionStatus) {
      return <Exclamation fill={colors.yellow600} size={minimized ? 12 : 16} />;
    }

    return null;
  }, [disconnectionStatus, minimized, outageStatus]);

  return content;
};

export default IntegrationStatusIcon;
