import React from "react";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { DateValue, parseDate } from "@puzzle/utils";

import { useActiveCompany } from "components/companies";

export const useExpensesExceededFeatureGate = () => {
  const { expenseExceededDate: inDate } = useActiveCompany<true>();

  // @ts-expect-error (inDate is already checked for existence)
  const expenseExceededDate = inDate ? parseDate(inDate) : null;
  const isExpensesExceeded = !!expenseExceededDate;

  const anyDatesExpenseGated = React.useCallback(
    (dates: DateValue[]) => {
      return dates.some((d) => {
        return d && expenseExceededDate && d >= expenseExceededDate;
      });
    },
    [expenseExceededDate]
  );

  if (isPosthogFeatureFlagEnabled(FeatureFlag.ExpensesDateFeatureGate) === false) {
    return {
      isExpensesExceeded: false,
      expenseExceededDate: null,
      anyDatesExpenseGated: () => false,
    };
  }

  return {
    isExpensesExceeded,
    expenseExceededDate,
    anyDatesExpenseGated,
  };
};
