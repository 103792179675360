import { create } from "zustand";

interface CmdkState {
  isCmdkOpen: boolean;
  setIsCmdkOpen: (val: boolean) => void;
  toggleCmdk: () => void;
}

export const useCmdkStore = create<CmdkState>((set) => ({
  isCmdkOpen: false,
  setIsCmdkOpen: (val) => set({ isCmdkOpen: val }),
  toggleCmdk: () => set((state) => ({ isCmdkOpen: !state.isCmdkOpen })),
}));
