import {
  BackArrow,
  Close,
  Copy,
  Resync,
  ThumbDown,
  ThumbDownFilled,
  ThumbUp,
  ThumbUpFilled,
} from "@puzzle/icons";
import { Button, IconButton, keyframes, styled, useToasts, ScrollArea } from "@puzzle/ui";
import { useWizardContext } from "components/common/Wizard";
import React, { useEffect, useState } from "react";
import { useCopyToClipboard } from "react-use";
import { useAIContext } from "./AIContext";
import { Body, Container, Header, HeaderTitle } from "./shared";
import { Box, S } from "ve";

const TextBubble = styled("div", {
  padding: "$2 $3 $2 $3",
  borderRadius: "$2",
  width: "fit-content",
  maxWidth: 420,
  textVariant: "$bodyS",

  defaultVariants: {
    question: false,
  },

  variants: {
    question: {
      true: {
        marginLeft: "auto",
        borderBottomRightRadius: 0,
        backgroundColor: "$purple800",
        color: "$white",
      },
      false: {
        borderBottomLeftRadius: 0,
        backgroundColor: "$rhino700",
        color: "$white",
      },
    },
  },
});

const Question = ({ message }: { message: string }) => {
  return <TextBubble question>{message}</TextBubble>;
};

const Answer = ({
  message,
  rating = 0,
  onRatingChange,
  onRegenerate,
}: {
  message: string;
  rating?: number;
  onRatingChange?: (rating: number) => void;
  onRegenerate?: () => void;
}) => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const { toast } = useToasts();

  return (
    <div>
      <Box css={{ display: "flex" }}>
        <TextBubble>{message}</TextBubble>
        <Box
          css={{
            display: "flex",
            alignItems: "center",
            height: 12,
            marginTop: S["1"],
            marginLeft: S["0h"],
            marginRight: "auto",
          }}
        >
          <IconButton
            onClick={() => {
              onRatingChange?.(rating === 1 ? 0 : 1);
            }}
          >
            {rating === 1 ? <ThumbUpFilled /> : <ThumbUp />}
          </IconButton>
          <IconButton
            onClick={() => {
              onRatingChange?.(rating === -1 ? 0 : -1);
            }}
          >
            {rating === -1 ? <ThumbDownFilled /> : <ThumbDown />}
          </IconButton>
          <IconButton
            onClick={() => {
              copyToClipboard(message);
              toast({
                message: "Copied to clipboard!",
              });
            }}
          >
            <Copy size={18} />
          </IconButton>
        </Box>
      </Box>
      <Box css={{ display: "flex", alignItems: "center", marginTop: S["0h"] }}>
        <Button prefix={<Resync size={12} />} variant="minimal" size="mini" onClick={onRegenerate}>
          Regenerate response
        </Button>
      </Box>
    </div>
  );
};

const pulse = keyframes({
  "0%": { opacity: 0.4 },
  "100%": { opacity: 1 },
});

const Dot = styled("div", {
  width: 8,
  height: 8,
  borderRadius: "$ellipse",
  backgroundColor: "$gray600",
  opacity: 0.4,
});

const Loading = () => {
  return (
    <TextBubble>
      <Box
        css={{
          display: "flex",
          alignItems: "center",
          gap: S["1"],
        }}
      >
        <Dot css={{ animation: `${pulse} 600ms ease-in-out 0s infinite alternate` }} />
        <Dot css={{ animation: `${pulse} 600ms ease-in-out 300ms infinite alternate` }} />
        <Dot css={{ animation: `${pulse} 600ms ease-in-out 600ms infinite alternate` }} />
      </Box>
    </TextBubble>
  );
};

export const AIChat = ({ onOpenChange }: { onOpenChange: () => void }) => {
  const { goToPreviousStep } = useWizardContext();
  const { prompt } = useAIContext();
  const [textBubbles, setTextBubbles] = useState<
    { type: "question" | "answer"; message: string; rating?: number }[]
  >([
    {
      type: "question",
      message: prompt,
    },
    {
      type: "answer",
      message: `When interpreting a balance sheet, compare numbers in each section to calculate ratios for analyzing financial stability. Look at the ratio of liabilities to assets at your company to get an idea of its value and performance. Having significantly more assets than liabilities indicates financial freedom and potential for growth.

      A cash flow statement provides an overview of how a company raised and spent money over a certain period. Use the cash flow statement to ensure that your business is able to cover short term expenses and analyze where the money in your business account comes from.`,
      rating: 0,
    },
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Box css={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Header>
        <HeaderTitle>
          <IconButton onClick={goToPreviousStep}>
            <BackArrow color="currentColor" size={12} />
          </IconButton>
          <div>Ask AI about my business</div>
        </HeaderTitle>
        <div>
          <IconButton onClick={onOpenChange} autoFocus>
            <Close color="currentColor" size={12} />
          </IconButton>
        </div>
      </Header>

      <ScrollArea css={{ flex: 1 }}>
        <Body>
          <Container>
            <Box
              css={{
                display: "flex",
                flexDirection: "column",
                gap: S["2"],
                width: "100%",
                height: "100%",
                marginTop: S["3"],
                marginBottom: S["3"],
              }}
            >
              {textBubbles.map((textBubble, index) => {
                if (textBubble.type === "question") {
                  return <Question key={index} message={textBubble.message} />;
                }

                if (index === textBubbles.length - 1 && loading) {
                  return <Loading key={index} />;
                }

                return (
                  <Answer key={index} message={textBubble.message} rating={textBubble.rating} />
                );
              })}
            </Box>
          </Container>
        </Body>

        <ScrollArea.Scrollbar orientation="vertical" css={{ width: "10px !important" }}>
          <ScrollArea.Thumb variant="shadowed" />
        </ScrollArea.Scrollbar>
      </ScrollArea>
    </Box>
  );
};
