import { useActiveCompany } from "components/companies";
import useSelf from "components/users/useSelf";
import { BookkeeperPlan } from "graphql/types";
import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import useTransactionsNeedingCategorizationReview from "components/transactions/useSignificantTransactions";

export interface POMChecklistState {
  isPreparingYourFinancialsChecked: boolean;
  isBookkeepingPlanChecked: boolean;
  isOnboardingCallChecked: boolean;
  isCategorizationModelChecked: boolean;
  isDashboardTourChecked: boolean;
  isSneakPeekChecked: boolean;
  isPositionChecked: boolean;
}

const SETTING_KEY_ONBOARDING_CALL = "pom3-onboarding-call";
const SETTING_KEY_DASHBOARD_TOUR = "pom3-dashboard-tour";
const SETTING_KEY_SNEAK_PEEK = "pom3-sneak-peek";
const SETTING_KEY_CATEGORIZE_TRANSACTIONS = "pom3-categorize-transactions";

export const usePOMChecklist = () => {
  const { messageSettings, refetch: refetchMessageSettings } = useSelf();
  const { company, membership, initialIngestCompleted } = useActiveCompany<true>();

  const { transactions, loading } = useTransactionsNeedingCategorizationReview(company!.id, {
    prefetch: false,
    pageSize: 1,
    startDate: undefined,
    endDate: undefined,
    options: {
      fetchPolicy: "cache-first",
    },
  });

  const isPreparingYourFinancialsChecked = initialIngestCompleted;
  const isBookkeepingPlanChecked =
    !!company?.bookkeeperPlan && company.bookkeeperPlan !== BookkeeperPlan.Unknown;
  const isOnboardingCallChecked = messageSettings?.[SETTING_KEY_ONBOARDING_CALL] ?? false;
  const isSneakPeekChecked = messageSettings?.[SETTING_KEY_SNEAK_PEEK] ?? false;
  const isCategorizationModelChecked =
    (messageSettings?.[SETTING_KEY_CATEGORIZE_TRANSACTIONS] ?? false) ||
    (!loading && transactions.length <= 0);
  const isDashboardTourChecked = messageSettings?.[SETTING_KEY_DASHBOARD_TOUR] ?? false;
  const isPositionChecked = !!membership?.position;

  const embedProgress = [
    isPreparingYourFinancialsChecked,
    isBookkeepingPlanChecked,
    isOnboardingCallChecked,
    isCategorizationModelChecked,
    isDashboardTourChecked || isSneakPeekChecked,
  ].reduce((acc, val) => acc + (val ? 20 : 0), 0);

  // This is only for showing/hiding the pom nav button
  const pomProgress = [
    isPreparingYourFinancialsChecked,
    isBookkeepingPlanChecked,
    isOnboardingCallChecked,
    isCategorizationModelChecked,
  ].reduce((acc, val) => acc + (val ? 25 : 0), 0);

  return {
    embedProgress, // 0, 25, 50, 75, 100
    loading, // Used for hiding the button.
    pomProgress,
    checklistState: {
      isPreparingYourFinancialsChecked,
      isBookkeepingPlanChecked,
      isOnboardingCallChecked,
      isCategorizationModelChecked,
      isDashboardTourChecked,
      isPositionChecked,
      isSneakPeekChecked
    },
    refetchMessageSettings,
  };
};

export const usePOMChecklistUpdate = () => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const markOnboardingCallAsChecked = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY_ONBOARDING_CALL,
          value: true,
        },
      },
    });
  };

  const markDashboardTourAsChecked = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY_DASHBOARD_TOUR,
          value: true,
        },
      },
    });
  };

  const markCategorizationModelAsChecked = () =>
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY_CATEGORIZE_TRANSACTIONS,
          value: true,
        },
      },
    });

  const markSneakPeekAsChecked = () =>
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY_SNEAK_PEEK,
          value: true,
        },
      },
    });

  return {
    markOnboardingCallAsChecked,
    markDashboardTourAsChecked,
    markCategorizationModelAsChecked,
    markSneakPeekAsChecked
  };
};
