import React, { useEffect, useMemo, useState } from "react";

import { Menu, Avatar, styled } from "@puzzle/ui";
import { zIndex } from "@puzzle/utils";

import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import useAppRouter from "lib/useAppRouter";

import useSelf from "components/users/useSelf";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { MenuButton } from "./MenuButtonStyles";
import useEffectOnRouteChange from "./useEffectOnRouteChange";
import { MembershipRole } from "graphql/types";

const UserLabel = styled(Menu.Label, {
  // ah, variants are more specific
  "&&": {
    background: "transparent !important",
  },

  minWidth: 180,
  textVariant: "$bodyXS",
  color: "$gray400",

  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  padding: "$1 $2",
});

const UserName = styled("div", {
  textVariant: "$headingS",
  color: "$gray50",
});

const ProfileMenu = ({
  minimized,
  showAllOptions = true,
}: {
  minimized: boolean;
  showAllOptions?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { logout, goToBilling, goToAuditLog, goToNotificationSettings } = useAppRouter();
  const { self } = useSelf();
  const { membershipRole, membership } = useActiveCompany<true>();
  const isNavV2 = isPosthogFeatureFlagEnabled(FeatureFlag.NavigationV2);

  const isAdmin = useMemo(
    () => membershipRole && (["Admin", "Bookkeeper"] as MembershipRole[]).includes(membershipRole),
    [membershipRole]
  );

  useEffectOnRouteChange(() => setOpen(false));

  useEffect(() => {
    if (open) {
      Analytics.profileMenuOpened();
    }
  }, [open]);

  if (!self) return null;

  return (
    <Menu
      arrow={!minimized}
      side={minimized ? "right" : "bottom"}
      align={minimized ? "center" : "start"}
      sideOffset={minimized ? 24 : 4}
      modal={false}
      open={open}
      onOpenChange={setOpen}
      trigger={
        <MenuButton
          data-testid="ProfileMenu"
          minimized={minimized}
          css={{ zIndex: zIndex("profileMenu") }}
        >
          <Avatar user={{ email: self.email, name: self.name ?? "Unknown" }} />
        </MenuButton>
      }
      css={{ zIndex: 2 }}
    >
      <UserLabel>
        <UserName>{self.name}</UserName>
        <div>{self.email}</div>
      </UserLabel>

      <>
        <Menu.Separator />
        {showAllOptions && (
          <Menu.Group>
            <Menu.Item
              onSelect={() => {
                Analytics.notificationSettingsClicked({
                  location: "profile-menu",
                });
                goToNotificationSettings();
              }}
            >
              Notification settings
            </Menu.Item>
            {isAdmin && !isNavV2 && (
              <Menu.Item onSelect={() => goToBilling()}>Plans & Billing</Menu.Item>
            )}
            {!isNavV2 && <Menu.Item onSelect={() => goToAuditLog()}>Audit log</Menu.Item>}
          </Menu.Group>
        )}
      </>
      <Menu.Separator />
      <Menu.Group>
        <Menu.Item onSelect={() => logout()}>Log out</Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default ProfileMenu;
