import React from "react";
import { CSS, styled, Tag, Tooltip } from "@puzzle/ui";
import Link from "next/link";
import { useRouter } from "next/router";

import { NavigationItemContent } from "./types";

const Title = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$1",
  transition: "0.15s all ease-in-out",
  whiteSpace: "nowrap",
});

const Container = styled("a", {
  display: "grid",
  gridTemplateColumns: "24px 1fr",
  gap: "$2",
  gridAutoColumns: "min-content",
  gridAutoFlow: "column",
  alignItems: "center",
  height: "40px",
  padding: "$1",
  borderRadius: "$1",
  fontSize: "14px",
  fontWeight: "$bold",
  textDecoration: "none",
  color: "$gray300",
  cursor: "pointer",
  outline: "none",
  position: "relative",

  "&, svg": {
    transition: "0.1s ease-in",
    transitionProperty: "background, color, filter, opacity",
  },

  defaultVariants: {
    disabled: false,
    minimized: false,
    selected: false,
  },

  variants: {
    selected: {
      true: {
        "&, &:hover": {
          backgroundColor: "#2f2e47",
        },
      },

      false: {},
    },

    disabled: {
      true: {
        color: "$gray500",
        pointerEvents: "none",
      },

      false: {
        "&:active, &:focus, &:hover": {
          backgroundColor: "#26253c",
          color: "$white",
        },
      },
    },

    minimized: {
      true: {
        gap: "1px",
        [`${Title}`]: {
          opacity: "0",
          width: 0,
        },
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      disabled: false,
      selected: false,
      css: {
        "&:active, &:focus, &:hover": {
          svg: {
            filter: "brightness(1.2)",
          },
        },
      },
    },
  ],
});

const Icon = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "24px",
  lineHeight: "0",
});

// TODO this should've used flexbox
const NavigationItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{
    item: NavigationItemContent;
    minimized?: boolean;
    disabled?: boolean;
    beta?: string;
    css?: CSS;
    selected?: boolean;
  }>
>(function NavigationItem({ children, item, minimized, beta, selected: _selected, ...props }, ref) {
  const router = useRouter();
  const { icon, title, disabled, disabledMessage, path, matchPath, external, onClick } = item;
  const isSelected =
    _selected ||
    (typeof path !== "undefined" &&
      [router.asPath, router.pathname].some((x) =>
        path === "/" ? x === path : x.startsWith(matchPath || path)
      ));

  const LinkWrapper = ({ children }: { children: React.ReactNode }) => {
    return typeof path === "undefined" ? (
      <>{children}</>
    ) : (
      <Link href={path} passHref legacyBehavior>
        {children}
      </Link>
    );
  };

  const link = (
    <div ref={ref}>
      <LinkWrapper>
        <Container
          disabled={disabled}
          selected={isSelected}
          minimized={minimized}
          onClick={onClick}
          target={external ? "_blank" : undefined}
          {...props}
        >
          {icon && (
            <Icon>{React.cloneElement(icon, { active: isSelected ? "true" : undefined })}</Icon>
          )}

          <Title>
            {title}

            {beta && (
              <Tag variant="outlined" color="vivid" css={{ fontSize: "10px", padding: "2px 6px" }}>
                {beta}
              </Tag>
            )}
          </Title>

          {children}
        </Container>
      </LinkWrapper>
    </div>
  );

  return (
    <Tooltip
      content={disabled ? disabledMessage : title}
      side="right"
      sideOffset={24}
      arrow={false}
      variant="bright"
      open={!minimized ? (disabled ? undefined : false) : undefined}
    >
      {link}
    </Tooltip>
  );
});

export default NavigationItem;
