import React, { useRef } from "react";
import { useLocalStorage } from "react-use";

import NextLink from "next/link";

import { ChevronDown, Crown, Search } from "@puzzle/icons";

import useAppRouter from "lib/useAppRouter";

import { DynamicReportType } from "graphql/types";
import { Route } from "lib/routes";
import useRouteAccess from "lib/useRouteAccess";

import { useCmdkStore } from "components/common/cmdk/cmdkStore";
import { useStickyReportContext } from "components/reports/StickyReportContext";

import { Stack, Text, color, S } from "ve";

import {
  collapseChevronStyle,
  itemStyle,
  triggerStyle,
  contentStyle,
  subItemStyle,
  rootItemLinkStyle,
  subItemLinkStyle,
  cmkdKItemStyle,
} from "./navItems.css";

export const NavSection = ({
  title,
  icon,
  children,
  routes,
  localStoreKey,
  sectionGap = false,
}: {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  routes: Route[];
  localStoreKey: string;
  sectionGap?: boolean;
}) => {
  const { hasAccess } = useRouteAccess();
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useLocalStorage(localStoreKey, false);

  if (!routes.some(hasAccess)) {
    return null;
  }

  return (
    <li style={{ marginTop: sectionGap ? S.$3 : "initial" }}>
      <button className={triggerStyle} onClick={() => setOpen(!open)}>
        {icon}
        <Text variant="bodyS" weight="bold">
          {title}
        </Text>
        <ChevronDown className={collapseChevronStyle} data-open={open ? "open" : undefined} />
      </button>

      <div
        ref={contentRef}
        className={contentStyle}
        style={{
          height: open ? contentRef.current?.scrollHeight : 0,
        }}
      >
        {children}
      </div>
    </li>
  );
};

export const RootNavItem = ({
  title,
  icon,
  route,
  suffix,
}: {
  title: string;
  icon: React.ReactNode;
  route: Route;
  suffix?: React.ReactNode;
}) => {
  const { hasAccess } = useRouteAccess();
  const { getCurrentRoute } = useAppRouter();
  const isActive = getCurrentRoute() === route;

  if (!hasAccess(route)) {
    return null;
  }

  return (
    <li className={itemStyle}>
      <NextLink
        className={rootItemLinkStyle}
        href={route}
        data-active={isActive ? "active" : undefined}
      >
        <Stack direction="horizontal" css={{ gap: S.$1h, alignItems: "center" }}>
          {icon}
          <Text variant="bodyS" weight="bold">
            {title}
          </Text>
          {suffix}
        </Stack>
      </NextLink>
    </li>
  );
};

export const SubNavItem = ({
  title,
  route,
  featureGated = false,
}: {
  title: string;
  route: Route;
  featureGated?: boolean;
}) => {
  const { hasAccess } = useRouteAccess();
  const { getCurrentRoute } = useAppRouter();
  const isActive = getCurrentRoute() === route;

  if (!hasAccess(route)) {
    return null;
  }

  return (
    <li className={subItemStyle}>
      <NextLink
        className={subItemLinkStyle}
        href={route}
        data-active={isActive ? "active" : undefined}
      >
        <Stack direction="horizontal" css={{ gap: S.$1h }}>
          <Text variant="bodyS" weight="bold">
            {title}
          </Text>
          {featureGated && <Crown color="#666666" style={{ marginLeft: "auto" }} />}
        </Stack>
      </NextLink>
    </li>
  );
};

export const DashboardSubNavItem = ({
  title,
  type,
}: {
  title: string;
  type: DynamicReportType;
}) => {
  const {
    stickyOptions: { activeTab },
    setStickyOptions,
  } = useStickyReportContext();
  const { getCurrentRoute } = useAppRouter();

  const href = Route.home;
  const isActive = getCurrentRoute() === href && activeTab === type;

  return (
    <li className={subItemStyle}>
      <NextLink
        className={subItemLinkStyle}
        href={href}
        data-active={isActive ? "active" : undefined}
        onClick={() => setStickyOptions({ activeTab: type })}
      >
        <Stack direction="horizontal" css={{ gap: S.$1h }}>
          <Text variant="bodyS" weight="bold">
            {title}
          </Text>
        </Stack>
      </NextLink>
    </li>
  );
};

export const CmdKItem = () => {
  const { setIsCmdkOpen, isCmdkOpen } = useCmdkStore();

  return (
    <li
      className={cmkdKItemStyle}
      onClick={() => setIsCmdkOpen(true)}
      data-active={isCmdkOpen ? "active" : undefined}
    >
      <Search size={20} color={color.greenalpha} />
      <Text variant="bodyS">Search</Text>
      <span style={{ marginLeft: "auto" }}>
        <Text variant="bodyXS">⌘K</Text>
      </span>
    </li>
  );
};
