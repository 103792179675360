import React from "react";
import { Plan, PricingPlanNames } from "../types";
import { formatMoney } from "@puzzle/utils";
import { Button, Stack, Text, styled } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import useMonetization from "../useMonetization";
import { YearlyDiscountBadge } from "./Badges";
import { SubscriptionBillingCycle } from "../types";
import { FreePlanFeatures, StartupPlanFeatures, AdvancedPlanFeatures } from "./PlanFeatures";
import { Box, S, vars } from "ve";

const TierStack = styled("div", {
  background: "rgba(41, 39, 62, 0.1)",
  borderRadius: "$2",
  border: "1px solid $white100",
  flexShrink: 1,
  flexBasis: "386px",
  gap: 0,
  position: "relative",
});

const SpecialBorderStack = styled("div", {
  flexShrink: 1,
  flexBasis: "386px",

  variants: {
    border: {
      special: {
        "@s": {
          marginTop: "0",
        },
        "@m": {
          marginTop: "-26px",
        },
      },
    },
  },
});

const SpecialBorderBox = styled("div", {
  color: "$green600",
  display: "flex",
  fontSize: "8px",
  lineHeight: "normal",
  textTransform: "uppercase",
  letterSpacing: "1.5px",
  fontWeight: "700",
  padding: "$1 0",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(114, 242, 166, 0.20)",
  borderRadius: "$2 $2 0 0",
  userSelect: "none",
  position: "relative",

  // top 100% pushes this box (which represents just the background)
  // below the special box and the height 8px should cover the
  // borderRadius of the tier box
  "&::after": {
    position: "absolute",
    display: "block",
    width: "100%",
    borderRadius: 0,
    content: "",
  },
});

// I don't like using fixed height at all but it seems to be the only way to make it work.
// 24px is the height of the special box, so -24px should cover that and it should look
// better aligned.
export const PlanBox = ({
  id: tierId,
  title,
  description,
  price,
  priceYear,
  badges,
  upgradeButtonBody,
  upgradeButtonVariant,
  featureTitle,
  features,
  specialBody,
  specialBackground,
  specialBodyColor,
  specialTierBorder,
}: Plan) => {
  const { isOnFreeTrial, isOnPaidPlan, currentSubscription } = useActiveCompany<true>();
  const { setSelectedPlan, selectedCycle } = useMonetization();
  const calculateTotalPrice = () => {
    if (price && priceYear) {
      const val = selectedCycle === SubscriptionBillingCycle.Monthly ? price : priceYear;
      return formatMoney({ currency: "USD", amount: val }, { truncateValue: false });
    }
    return null;
  };

  const totalPrice = calculateTotalPrice();
  const showDiscountBadge = selectedCycle === SubscriptionBillingCycle.Yearly && totalPrice;

  return (
    <SpecialBorderStack border={specialBody ? "special" : undefined}>
      {specialBody && (
        <SpecialBorderBox
          css={{
            background: specialBackground,
            color: specialBodyColor,
            "&::after": {
              background: specialBackground,
            },
          }}
        >
          {specialBody}
        </SpecialBorderBox>
      )}
      <TierStack
        css={{
          border: specialTierBorder ?? undefined,
          borderRadius: specialTierBorder ? "0 0 $2 $2" : "$2",
          width: "376px",
          height: specialBody ? "calc(100% - 24px)" : "100%",
        }}
      >
        <Box
          css={{
            background: "rgba(0, 0, 0, 0.20)",
            padding: S["2h"],
            display: "flex",
            borderTopLeftRadius: vars.radii["2"],
            borderTopRightRadius: vars.radii["2"],
            flexDirection: "column",
            gap: S["1h"],
            height: "126px",
            width: "386px",
          }}
        >
          <Text
            color="gray200"
            data-testid={`title-tier-${tierId}`}
            weight="bold"
            lineHeight="heading2"
            css={{ fontSize: "28px" }}
          >
            {title}
          </Text>
          <Text variant="bodyM" color="gray400">
            {description}
          </Text>
        </Box>

        <Stack css={{ padding: "$2h", gap: "$2" }}>
          <Stack
            css={{
              flexDirection: "row",
              alignItems: "center",
              gap: "$1h",
            }}
          >
            <Text variant="heading1" color="gray200" weight="bold">
              {totalPrice ? `${totalPrice}` : "Free"}
            </Text>
            <Text variant="headingM" color="gray400">
              {totalPrice ? (
                selectedCycle === SubscriptionBillingCycle.Monthly ? (
                  `/ month`
                ) : (
                  "/ year"
                )
              ) : (
                <br />
              )}
            </Text>
            {showDiscountBadge && <YearlyDiscountBadge />}
          </Stack>

          <Button
            id={`upgrade-button-tier-${tierId}`}
            fullWidth
            data-testid={`upgrade-button-tier-${tierId}`}
            variant={upgradeButtonVariant}
            onClick={() => setSelectedPlan(title, { shallow: true })}
            disabled={currentSubscription === tierId}
          >
            {typeof upgradeButtonBody === "function"
              ? upgradeButtonBody({ isOnFreeTrial, isOnPaidPlan, currentSubscription })
              : upgradeButtonBody}
          </Button>

          <Text variant="bodyM" color="gray400">
            {featureTitle}
          </Text>
          {tierId === PricingPlanNames.FREE && <FreePlanFeatures />}
          {tierId === PricingPlanNames.PAID_PLAN_1 && <StartupPlanFeatures />}
          {tierId === PricingPlanNames.PAID_PLAN_2 && <AdvancedPlanFeatures />}
        </Stack>
      </TierStack>
    </SpecialBorderStack>
  );
};
