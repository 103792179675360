import React, { useState } from "react";
import { Stack, Text, styled, colors } from "@puzzle/ui";
import { Check, Clear, Sparkle } from "@puzzle/icons";
import {
  Efficieny1,
  Efficieny2,
  Compliance,
  Other,
  Insights,
  Fundraise,
  Accuracy,
  Support,
} from "./icons";
import { AccrualAutomationModal } from "./AccrualAutomationModal";

const Section = styled("div", {
  borderTop: "1px solid rgba(256, 256, 256, 00.12)",
  paddingTop: "$2",
});

const SECTION_1_HEIGHT = "132px";
const SECTION_2_HEIGHT = "100px";

const StyledCheck = () => {
  return <Check css={{ flexShrink: 0 }} size={12} color={colors.purple800} />;
};

const Title = ({ icon, title }: { icon?: React.ReactElement; title: string }) => {
  return (
    <Stack direction="horizontal" css={{ alignItems: "center", gap: "$1" }}>
      {icon}
      <Text color="gray200" variant="headingM">
        {title}
      </Text>
    </Stack>
  );
};

const BulletPoint = ({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactElement;
  text: string;
  onClick?: () => void;
}) => {
  return (
    <Stack
      direction="horizontal"
      css={{ alignItems: "center", gap: "$1", textVariant: "$bodyM", paddingTop: "$1" }}
    >
      {icon}
      <Text
        color="gray400"
        css={{
          textDecoration: onClick ? "underline" : undefined,
          cursor: onClick ? "pointer" : "auto",
        }}
        onClick={onClick}
      >
        {text}
      </Text>
    </Stack>
  );
};

export const FreePlanFeatures = () => {
  return (
    <Stack>
      <Section css={{ height: SECTION_1_HEIGHT }}>
        <Title icon={<Efficieny1 />} title="EFFICIENCY" />
        <BulletPoint icon={<StyledCheck />} text="Automated data import" />
        <BulletPoint icon={<StyledCheck />} text="Rule-based auto-categorization" />
        <BulletPoint icon={<StyledCheck />} text="Auto-generated financial statements" />
      </Section>
      <Section css={{ height: SECTION_2_HEIGHT }}>
        <Title icon={<Compliance />} title="COMPLIANCE" />
        <BulletPoint icon={<StyledCheck />} text="1-click tax package" />
      </Section>
      <Section>
        <Title icon={<Other />} title="OTHER" />
        <BulletPoint icon={<Clear />} text="Monthly expense limit of $15,000" />
      </Section>
    </Stack>
  );
};

export const StartupPlanFeatures = () => {
  return (
    <Stack>
      <Section css={{ height: SECTION_1_HEIGHT }}>
        <Title icon={<Insights />} title="INSIGHTS & FOCUS" />
        <BulletPoint icon={<StyledCheck />} text="Smart spotlight on financial outliers" />
        <BulletPoint icon={<StyledCheck />} text="Real-time spend, revenue, & vendor analysis" />
      </Section>
      <Section css={{ height: SECTION_2_HEIGHT }}>
        <Title icon={<Fundraise />} title="FUNDRAISE READY" />
        <BulletPoint icon={<StyledCheck />} text="Financial health metrics" />
      </Section>
      <Section>
        <Title icon={<Other />} title="OTHER" />
        <BulletPoint icon={<StyledCheck />} text="No monthly expenses limit" />
        <BulletPoint
          icon={<Sparkle color={colors.purple800} />}
          text="AI Automation & Accuracy addons available"
        />
      </Section>
    </Stack>
  );
};

export const AdvancedPlanFeatures = () => {
  const [openAutomationModal, setOpenAutomationModal] = useState(false);
  return (
    <Stack>
      <Section css={{ height: SECTION_1_HEIGHT }}>
        <Title icon={<Efficieny2 />} title="EFFICIENCY" />
        <BulletPoint icon={<StyledCheck />} text="Accrual automation & revenue recognition" />
        <BulletPoint icon={<StyledCheck />} text="Auto-reconciliations" />
        <BulletPoint icon={<StyledCheck />} text="AI categorization tools & workflows" />
      </Section>
      <Section css={{ height: SECTION_2_HEIGHT }}>
        <Title icon={<Accuracy />} title="ACCURACY" />
        <BulletPoint icon={<StyledCheck />} text="Filterable audit log" />
        <BulletPoint icon={<StyledCheck />} text="Team collaboration tools" />
      </Section>
      <Section>
        <Title icon={<Other />} title="OTHER" />
        <BulletPoint icon={<StyledCheck />} text="No monthly expenses limit" />
        <BulletPoint
          icon={<Sparkle color={colors.purple800} />}
          text="AI Automation & Accuracy features include"
        />
        <BulletPoint
          icon={<StyledCheck />}
          text="Accrual automation $0.25 per automation"
          onClick={() => setOpenAutomationModal(true)}
        />
        <BulletPoint icon={<Support />} text="Priority live support" />
      </Section>
      <AccrualAutomationModal open={openAutomationModal} onOpenChange={setOpenAutomationModal} />
    </Stack>
  );
};
