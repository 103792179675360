export const PROMPTS = {
  financialStatement: "How do I properly read a financial statement?",
  bankReconciliation: "Explain bank reconciliations like I’m 5.",
  investorQuestions: "What are the most common questions investors will ask?",
  weakPoints:
    "What are the top three weak points of my business from a financial metrics perspective, and what can I do to improve?",
  businessHistory:
    "Tell me something about my business based on my historical cash balance and burn.",
  taxSubmission: "What do I need to have to submit taxes for my business?",
  taxExtension: "How can I file a tax extention?",
};
